<div class="containerMain">
  <p class="title">{{solicitation.tipo_item}} / {{solicitation.tipo_evento.descricao}}</p>
<div class="container-card">
  <h3>{{titleSolicitation || "Procediment não informado."}}</h3>
  <div class="container-pdfs">
    <mai-btn iconLeft='../../../../assets/icons/pdf.svg' type="text" theme='info' label="VISUALIZAR DIRETRIZ"></mai-btn>
  </div>
  <div class="containerAlert" *ngFor="let ocorr of ocorrencias">
    <app-alert-fixed
      [message]="ocorr"
    ></app-alert-fixed>
  </div>
  <div class="container-infos">
    <div class="order-infos__inf" *ngFor="let params of restParams">
      <p>{{params.rotulo}}: <strong>{{params.valor}}</strong></p>
    </div>
    <div class="container-btns">
      <mai-replybtn
        class="container-btns__width"
        [data]="[{ type: 'warning', label: 'INFORMAR PENDÊNCIA', value: '1' }, { type: 'danger', label: '', value: '2' }, { type: 'success', label: '', value: '3' }]"
        (clicked)="handeRequest($event)"
      >
      </mai-replybtn>
    </div>

    <div class="request_order">
      <div class="container_autorization_qtd" *ngIf="formRequestSelected === 'aprovada' && dataSelect.length > 0 ">
        <h3>Quantidade autorizada</h3>
        <div class="container_select_qtd">
          <mai-select
            [isDefault]="false"
            [data]="dataSelect"
            placeholder='Selecione a quantidade...'
            (clicked)="handleSelected($event)"
            [selected]="quantAutoString"
          ></mai-select>
        </div>
      </div>

      <div class="container_autorization_qtd" *ngIf="formRequestSelected === 'aprovada' && paramsEditRegulacaoDecimal.rotulo !== undefined">
        <h3>{{paramsEditRegulacaoDecimal.rotulo}}</h3>
        <div class="container_select_qtd">
          <mai-input
            [isDefault]='false'
            placeholder="Insira quantidade"
            (change)="handleQuatAutDecimal($event)"
            [state]="!!quantAutorizadaError ? 'danger' : 'default'"
            [assistiveText]='quantAutorizadaError'
          ><input
             mask="999,9"
            [value]="quantAutorizada"
            (focus)="quantAutorizadaError = ''"
          /></mai-input>
        </div>
      </div>

      <div class="container_reason" *ngIf="formRequestSelected === 'aprovada'">
        <h3>Qual o motivo dessa decisão?</h3>
        <div class="container_input_reason">
          <mai-input
            [isDefault]='false'
            placeholder="Insira o motivo aqui..."
            (input)="handleInputReason($event)"
            (clicked)="showDrawer = !showDrawer"
          ><input
            [value]="respostaMotivo"
          /></mai-input>
        </div>
      </div>

      <div class="container_reason" *ngIf="formRequestSelected === 'recusada'">
        <h3>Qual o motivo dessa decisão?</h3>
        <div class="container_input_reason">
          <mai-input
            iconRight='mai-ic-formpack'
            [isDefault]='false'
            placeholder="Insira o motivo aqui..."
            (input)="handleInputReason($event)"
            (clicked)="handleOpenDrawer('recusada')"
          ><input
            [value]="respostaMotivo"
          /></mai-input>

        </div>
      </div>

      <div class="container_reason" *ngIf="formRequestSelected === 'pendente'">
        <h3>Qual o motivo dessa decisão?</h3>
        <div class="container_input_reason">
          <mai-input
            iconRight='mai-ic-formpack'
            [isDefault]='false'
            placeholder="Insira o motivo aqui..."
            (input)="handleInputReason($event)"
            (clicked)="handleOpenDrawer('pendente')"
          ><input
            [value]="respostaMotivo"
          /></mai-input>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<!-- INICIO DRAWER DE MOTIVOS -->
<mai-drawer
  (clickWrapper)='showDrawer = !showDrawer'
  *ngIf='showDrawer'
>
<div class="contentDrawer" *ngIf="arrayGroupMotivos.length !== 0">
  <div class="contentDrawer__header" >
    <header>
      <button type="button" (click)="showDrawer = !showDrawer">
        <mai-svg-icon  icon="mai-ic-close" size='2'></mai-svg-icon>
      </button>

      <h2>Banco de motivos</h2>
    </header>
  </div>
  <div class="contentDrawer__searchBar">
    <mai-searchbar placeholder="Procurar motivo">
      <input (input)="handleSearchArrayMotivos($event)"/>
    </mai-searchbar>
  </div>

  <!-- TODOS OS MOTIVOS -->
  <div *ngIf="!consultStringSearch">
    <div class="contentDrawer__categorieMotivo" *ngFor="let grupo of arrayGroupMotivos">
      <p class="categorieTitle">{{grupo.nomeGrupo}}</p>
      <div class="categorieMotivo__list">
        <mai-radiobtn
          [data]="grupo.listaMotivos"
          (clicked)="handleSelectedMotivo($event)"
          [marked]="selectedMotivo.motivo"
        >
        </mai-radiobtn>
      </div>
    </div>
  </div>

   <!-- MOTIVOS  FILTRADOS NA BUSCA-->
   <div *ngIf="!!consultStringSearch">
    <div *ngIf="motivosBusca.length > 0">
      <div class="contentDrawer__categorieMotivo" *ngFor="let grupo of motivosBusca">
        <p class="categorieTitle">{{grupo.nomeGrupo}}</p>
        <div class="categorieMotivo__list">
          <mai-radiobtn
            [data]="grupo.listaMotivos"
            (clicked)="handleSelectedMotivo($event)"
            [marked]="selectedMotivo.motivo"
          >
          </mai-radiobtn>
        </div>
      </div>
     </div>

     <div class="results-not-found" *ngIf="motivosBusca.length === 0">
      <div class="container_illustration_not">
        <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
      </div>
      <div class="content_modal_rest">
        <h2 class="block">Não encontramos nenhum motivo</h2>
        <p class="block">Não há nenhum motivo para está solicitação</p>
      </div>
    </div>
  </div>

</div>

<div class="contentDrawer" *ngIf="arrayGroupMotivos.length === 0">
  <div class="contentDrawer__header" >
    <header>
      <button type="button" (click)="showDrawer = !showDrawer">
        <mai-svg-icon  icon="mai-ic-close" size='2'></mai-svg-icon>
      </button>

      <h2>Banco de motivos</h2>
    </header>
  </div>
  <div class="contentDrawer__searchBar">
    <mai-searchbar placeholder="Procurar motivo">
      <input />
    </mai-searchbar>
  </div>
  <div class="results-not-found">
    <div class="container_illustration_not">
      <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
    </div>
    <div class="content_modal_rest">
      <h2 class="block">Não encontramos nenhum motivo</h2>
      <p class="block">Não há nenhum motivo para está solicitação</p>
    </div>
  </div>
</div>
</mai-drawer>
