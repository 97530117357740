<div class="container" *ngIf="detectedDevice === 'DesktopOrTablet'">
  <div class="content">
    <div class="area-one">
      <button tyoe="button" (click)="handleBackPage()">
        <img src="../../../../assets/icons/arrow-left.svg" alt=""/>
      </button>
      <div>
        <mai-header [label]="headerLeft"></mai-header>
        <ng-content select=".details"></ng-content>
      </div>
    </div>

    <div class="area-two">
      <div class="area-two__stick">
        <div class="container_overflow">
          <mai-overflow
            [data]="dataOverflow"
            (clicked)="onClickOverflow($event)"
          ></mai-overflow>
        </div>
        <div class="contaiern_content_area_two">
          <mai-header [label]="headerRight"></mai-header>
          <ng-content select=".painel-main"></ng-content>
        </div>
      </div>
    </div>

    <!-- MODAL PARA Arquivar -->
    <mai-modal class="containerModalArquivar" *ngIf='show' (clickWrapper)='show = !show;' label='' title=''>
      <div class="contentModalArquivar">
        <h2>Deseja arquivar esta guia?</h2>
        <p>Esta guia será arquivada em sua fila, lembre-se que o SLA continuará contabilizando.</p>
        <div class="contentModalArquivar__btns">
          <mai-btn label="cancelar" (clicked)='show = !show' type='ghost'></mai-btn>
          <mai-btn [label]="loading ? 'Arquivando...' : 'sim, arquivar'" [disabled]="loading" (clicked)='handleArquivarGuia()'></mai-btn>
        </div>
      </div>
    </mai-modal>

    <mai-alert type='success' *ngIf='reqSuccess' label='Guia arquivada com sucesso.'></mai-alert>
    <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
  </div>
</div>


<div class="device" *ngIf="detectedDevice === 'Android' || detectedDevice === 'Ios'">
  <app-device-redirect [typeDevice]="detectedDevice"></app-device-redirect>
</div>
