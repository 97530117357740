<div class="container_search_box">
  <div class="container_search_box__Left">
    <h2>Olá, {{name}}</h2>
    <p>Caso você precise acessar uma guia específica, por favor, utilize o campo de busca abaixo.</p>
    <oct-search-bar></oct-search-bar>
    <div class="container_button_white">
      <button type="button" (click)='handleSearchData()'>
        ENTRAR NA FILA
      </button>
    </div>

    <mai-modal *ngIf='show' (clickWrapper)='show = !show;' label='' title=''>
     <div class="modalContent" *ngIf="guiaId > 0">
       <div class="container_lottie">
          <ng-lottie [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
       </div>
       <div class="content_modal_rest">
         <h2 class="block">Procurando guia</h2>
         <p class="sizeSearch block">Estamos procurando uma guia para você regular.</p>
       </div>
       <div class="buttons" *ngIf="searchData">
        <mai-btn label="cancelar" (clicked)='handleCancelReq()' theme="danger"></mai-btn>
       </div>
     </div>


     <div class="results-not-found">
        <div class="modalContent" *ngIf="guiaId === 0">
          <div class="container_illustration_not">
            <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
          </div>
          <div class="content_modal_rest">
            <h2 class="block">Não encontramos nenhuma guia</h2>
            <p class="block">Não há nenhuma guia aguardando regulação nos contextos selecionados.</p>
          </div>
        </div>
        <div class="buttons" *ngIf="guiaId === 0">
          <mai-btn label="fechar" (clicked)='show = !show'></mai-btn>
        </div>
     </div>

    </mai-modal>
  </div>
  <div class="container_search_box__right">
    <img src="../../../../assets/images/illustrations/homepage-search.svg" alt="">
  </div>
</div>


<!-- //ALERT -->
<mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
