import { createAction, props } from '@ngrx/store';
import { GuildeModel } from './guilde.model';

export enum ActionTypes {
  ADD_GUIDE = "ADD_GUILDE",
  RESPONSE_SOLICITATION = "RESPONSE_SOLICITATION",
  REMOVE_RESPONSE_SOLICITATION = "REMOVE_RESPONSE_SOLICITATION"
}

export const ADD_GUILDE = createAction(
  ActionTypes.ADD_GUIDE,
  props<{payload: GuildeModel}>()
)

export const RESPONSE_SOLICITATION = createAction(
  ActionTypes.RESPONSE_SOLICITATION,
  props<{payload: {}}>()
)

export const REMOVE_RESPONSE_SOLICITATION = createAction(
  ActionTypes.REMOVE_RESPONSE_SOLICITATION
)

