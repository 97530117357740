import { Injectable } from "@angular/core";
import { TokenService } from "src/app/core/auth/token/token.service";
import { Observable, Subject } from 'rxjs';
import { Router } from "@angular/router";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BASE_URL } from "../constants/api";

interface ResetPasswordData {
  code: string;
  password: string;
  confirmPassword: string;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private userSubject = new Subject();

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private http: HttpClient
  ) {
    if(this.tokenService.hasToken()){
      this.decodeToken()
    }
  }

  setToken(token: any) {
    this.tokenService.setToken(token)
    this.decodeToken()
  }

  getUser() {
    return this.userSubject.asObservable()
  }

  getUserData() {
    const userData: any = localStorage.getItem('@OCT:USER')
    const userDataFormat = JSON.parse(userData)

    return userDataFormat
  }

  private decodeToken() {
    const token = this.tokenService.getToken()

    const userDatas = btoa(token)

    this.userSubject.next(userDatas)
  }

  logout() {
    this.tokenService.removeToken()
    localStorage.removeItem('@OCT:USER')
    this.router.navigate([''])
  }

  isLogged() {
    return this.tokenService.hasToken()
  }

  isAdmin() {
    const userData: any = this.getUserData()
    const admin = userData.perfil === 'administrativo_portal';

    return admin
  }


  editDataUser(data: any, id?: number | string) {
    return this.http
    .put<HttpResponse<Object>>(`${BASE_URL}/usuarios/editar`, data, { observe: "response" })
  }

  newPasswordEdit(data: any, id?: number | string) {
    return this.http
    .put<HttpResponse<Object>>(`${BASE_URL}/autenticar/senha`, data, { observe: "response" })
  }

  removeGuideline(id: number, body: any): Observable<any> {
    return this.http
    .put<HttpResponse<Object>>(`${BASE_URL}/usuarios/editar/${id}`, body, { observe: "response" })
  }

  // endpoints p/ recuperacao de senha
  sendEmailCode(email: string): Observable<any> {
    return this.http
    .post<HttpResponse<Object>>(`${BASE_URL}/autenticar/senha/nova?email=${email}`, { observe: "response" })
  }

  autenticateCodeResetPassword(code: string): Observable<any> {
    return this.http
    .get<HttpResponse<Object>>(`${BASE_URL}/autenticar/token?reset_password_token=${code}`, { observe: "response" })
  }

  resetNewPassword({ code, password, confirmPassword }: ResetPasswordData): Observable<any> {
    return this.http
    .put<HttpResponse<Object>>(`${BASE_URL}/autenticar/senha?reset_password_token=${code}&password=${password}&password_confirmation=${confirmPassword}`, { observe: "response" })
  }
}
