import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DetectCell } from 'src/app/shared/utils/libs/mobileFunction';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit, AfterViewInit {
  email: string = ''
  senha: string = ''
  disabled: boolean = true

  loginForm: FormGroup | any;
  errorEmail: string = ''
  isErrorReqEmail = false
  isErrorReqPassword = false
  errorReq = ''
  loading = false
  errorProfileNotValid = ""

  detectedDevice = ''

  @ViewChild('emailInput') emailInputElement: ElementRef<HTMLInputElement> | undefined

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['']
    })

    this.resizeDispositivo()
  }

  resizeDispositivo() {
    this.detectedDevice = DetectCell()
  }

  ngAfterViewInit() {
    window.addEventListener('resize', this.resizeDispositivo.bind(this))
  }


  handleSignin(): void {
    this.isErrorReqEmail = false
    this.isErrorReqPassword = false
    this.errorEmail = this.loginForm.get("email").errors?.email ? 'Email é invalido' : ''

    if(!this.errorEmail){
      this.loading = true

      const email = this.loginForm.get('email').value;
      const password = this.loginForm.get('password').value;

      this.errorReq = ''

      this.authService
        .signIn(email, password)
        .subscribe(
          (res) => {
            if(res.body.data.perfil === 'administrativo_portal') {
              this.router.navigate(['gestor'])
            } else if(res.body.data.perfil === 'profissional'){
              this.router.navigate(['homepage'])
            } else {
              this.errorReq = "O perfil selecionado ainda não é suportado! Tente outro email."
            }

            this.loading = false
          },
          (error) => {
            this.loading = false
            this.isErrorReqEmail = true
            this.isErrorReqPassword = true
            this.errorReq = error.error.errors[0]
          }
        )
    }

  }

  removeErrorEmail(): any {
    if(this.isErrorReqEmail) {
      this.isErrorReqEmail = false
    }
  }

  handleRedirectForgot(): void {
    this.router.navigate(['recuperar-senha'])
  }

  removeErrorPassword(): any {
    this.isErrorReqPassword = false
  }

}
