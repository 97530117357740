import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-input',
  templateUrl: './loading-input.component.html',
  styleUrls: ['./loading-input.component.scss']
})
export class LoadingInputComponent implements OnInit {
  options: AnimationOptions = {
    path: '../../../../assets/lottie/input-loading.json',
  };


  constructor() { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
  }


}
