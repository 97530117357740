<div class="container" *ngIf="detectedDevice === 'DesktopOrTablet'">
  <header>
    <div *ngIf="!!photo">
      <mai-avatar
        (click)='handleDropdown()'
        [photo]="photo"
     ></mai-avatar>
    </div>

    <div *ngIf="!photo">
      <mai-avatar
        (click)='handleDropdown()'
      ></mai-avatar>
    </div>


    <mai-dropdown
      [data]="[{ label: 'Editar meus dados', icon: 'mai-ic-edit' }, { label: 'Sair', icon: 'mai-ic-close'}]"
      *ngIf='showDropdown'
      (clicked)="handleDrawer($event)"
    ></mai-dropdown>
  </header>

  <mai-drawer
     (clickWrapper)='handleDrawerUndefined()'
     *ngIf='show'
  >
    <div class="contentDrawer">
      <div class="contentDrawer__header" >
        <header>
          <button type="button" (click)="handleDrawer($event)">
            <mai-svg-icon  icon="mai-ic-close" size='2'></mai-svg-icon>
          </button>

          <h2>Editar meus dados</h2>
        </header>

        <div class="container-avatar">
          <div class="avatar-edit">
            <mai-avatar *ngIf="!!photo" size='large' [photo]="photo"></mai-avatar>
            <mai-avatar *ngIf="!photo" size='large'></mai-avatar>
            <!-- <mai-avatar *ngIf="!!photoUploaded.preview" [photo]="photoUploaded.preview" size='large'></mai-avatar> -->
            <input class="inputHiddenFile" type="file" name="avatar" accept="image/*" #file (change)="handleEditPhotoUser($event)">
            <div class="btn-alt-avatar" (click)="file.click()">
              <img src="../../../../assets/icons/camera.svg" alt="">
            </div>
          </div>
        </div>

        <form [formGroup]="editForm" (submit)="handleEditProfile()">
          <div class="content-form">
            <h2>Dados pessoais</h2>
            <div class="container-input">
              <label>Nome completo</label>
              <mai-input
                [isDefault]="false"
                placeholder="Insira seu nome"
              ><input
                formControlName="name"
              /></mai-input>
            </div>

            <div class="container-input">
              <label>Telefone</label>
              <mai-input
                [isDefault]="false"
                placeholder="Insira seu telefone"
                mask="phone"
              ><input
                formControlName="phone"
                maxlength="15"
              /></mai-input>
            </div>
          </div>

          <div class="content-form">
            <h2>Acesso à conta</h2>
            <!-- <div class="container-input">
              <label>Senha atual</label>
              <mai-input
                [isDefault]='false'
                [state]="isErroReqPassword  ? 'danger' : 'default'"
                placeholder="Insira aqui sua senha atual"
              ><input
                type="password"
                formControlName="currentPassword"
              /></mai-input>
            </div> -->

            <div class="container-input">
              <label>Nova senha</label>
              <mai-input
                [isDefault]="false"
                [state]="isErroReqPassword  ? 'danger' : 'default'"
                placeholder="Insira aqui a nova senha"
              ><input
                type="password"
                formControlName="password"
              /></mai-input>
            </div>

            <div class="container-input">
              <label>Confirmar senha</label>
              <mai-input
                [isDefault]='false'
                [state]="isErroReqPassword  ? 'danger' : 'default'"
                placeholder="Insira aqui a nova senha"
              ><input
                type="password"
                formControlName="confirmPassword"
              /></mai-input>
            </div>
          </div>

          <div class="conteiner-button">
            <mai-btn label="SALVAR"></mai-btn>
          </div>
        </form>
      </div>
    </div>
  </mai-drawer>

  <div select=".content" class="content">
    <ng-content></ng-content>
  </div>
</div>


<div class="device" *ngIf="detectedDevice === 'Android' || detectedDevice === 'Ios'">
    <app-device-redirect [typeDevice]="detectedDevice"></app-device-redirect>
</div>

<!-- ALERTS -->
<mai-alert type='success' *ngIf='!!labelAlertSuccess' [label]='labelAlertSuccess'></mai-alert>
<mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
