import { Injectable } from "@angular/core";

const TOKEN_KEY = '@OCT:TOKEN'

@Injectable({ providedIn: 'root' })
export class TokenService {
  hasToken() {
    return !!this.getToken()
  }

  setToken(token: any) {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token))
  }

  getToken(): string | any {
    const getHeaders: any = localStorage.getItem(TOKEN_KEY)
    const data = JSON.parse(getHeaders)
    return data?.token
  }

  getFullHeaders() {
    const getHeaders: any = localStorage.getItem(TOKEN_KEY)
    const data = JSON.parse(getHeaders)
    return data
  }

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  }

}
