export class GuildeModel {
  public id: number | any;
  public cliente: Object | any;
  public codigo_no_cliente: string | any;
  public historico: [] | any;
  public parametros_ordenados: [] | any;
  public pendencia_alerts: Object | any;
  public prazo_para_regulacao: string | any;
  public quadro_clinico: string | any;
  public solicitacoes: [] | any;
  public status: string | any;
}
