import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { BASE_URL } from "src/app/shared/utils/constants/api";
import { UserService } from "../../shared/utils/services/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  signIn(email: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${BASE_URL}/autenticar/entrar`, { email, password }, { headers: new HttpHeaders ({
        'Access-Control-Expose-Headers': '*, Authorization'
      }), observe: "response"})
      .pipe(tap(response => {
        const isProfileValid = response.body.data.perfil === 'administrativo_portal' || response.body.data.perfil === 'profissional'

        if(isProfileValid) {
          const configData = {
            token: response.headers.get('Access-Token'),
            client:  response.headers.get('Client'),
            uid: response.headers.get('Uid')
          }

          const userData = response.body?.data

          localStorage.setItem('@OCT:USER', JSON.stringify(userData))

          this.userService.setToken(configData)
        }
      }))
  }
}
