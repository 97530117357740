import { AfterViewInit, Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { DetectCell } from 'src/app/shared/utils/libs/mobileFunction';
import { UserService } from '../../../shared/utils/services/user.service'
import { PhotoUploadedData, UserLocalStorageData } from './template-one-column.model';

@Component({
  selector: 'app-template-one-column',
  templateUrl: './template-one-column.component.html',
  styleUrls: ['./template-one-column.component.scss']
})
export class TemplateOneColumnComponent implements OnInit, AfterViewInit, DoCheck {
  @ViewChild('page', { static: true })

  show: boolean = false
  showDropdown: boolean = false
  applyHover: boolean = false
  subClick: any = []

  //edit form
  editForm: FormGroup | any
  user: any = {}
  photo: any = ""
  photoUploaded: any = {}

  labelAlertSuccess = ''
  isErroReqPassword = false
  errorReq = ''

  detectedDevice = ''


  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {
    this.user = userService.getUserData()

    if(this.user.avatar.url.indexOf('/assets/fallback/avatar') === -1) {
      this.photo = this.user.avatar.url
    }
  }

  ngAfterViewInit() {
    window.addEventListener('resize', this.resizeDispositivo.bind(this))
  }

  ngDoCheck(): void {
    this.user = this.userService.getUserData()
    this.photo = this.user.avatar.url

    if(!this.show) {
      this.isErroReqPassword = false
      this.handleZeroFieldsPassword()
    }
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.user.nome],
      phone: [this.user.telefones[0].numero],
      password: [''],
      confirmPassword: [''],
      currentPassword: ['']
    })

    this.resizeDispositivo()
  }

  resizeDispositivo() {
    this.detectedDevice = DetectCell()
  }

  handleDrawer(e: any) {
    if(e.label === 'Sair') {
      this.userService.logout()
    }else{
      this.show = !this.show;
      this.photoUploaded = {}
    }

  }

  handleDrawerUndefined() {
    this.show = !this.show;
    this.photoUploaded = {}
  }


  /* FUNCOES RELACIONADAS AO DROPDOWN*/

  handleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.observeClick();
  }

  observeClick() {
    const click = fromEvent(document, 'click');
    let considerClick = false;
    this.subClick.push(click.subscribe((e) => {
      if (considerClick) {
        this.showDropdown = false;
        this.unsubscribe();
        return;
      }
      considerClick = true;
    }));
  }

  unsubscribe() {
    this.subClick.forEach((sub: any) => {
      sub.unsubscribe();
    });
    this.subClick = [];
  }

  handleRequestEditProfile(data: any, isNotReqNewPassword?: boolean) {
    this.userService.editDataUser(data)
      .subscribe(
        res => {
          const userData = res.body
          localStorage.setItem('@OCT:USER', JSON.stringify(userData))
          this.photoUploaded = {}

          if(isNotReqNewPassword) {
            this.labelAlertSuccess = 'Dados alterados com sucesso!'
            this.show = false
            setTimeout(() => {
              this.labelAlertSuccess = ''
            }, 2000)
          }
        },
        err => {
          if(isNotReqNewPassword) {
            this.labelAlertSuccess = 'Ocorreu um erro nos nosso servidores! Tente novamente em instantes.'
            setTimeout(() => {
              this.labelAlertSuccess  = ''
            }, 2000)
          }
        }
      )
  }

  //zerando campos de id
  handleZeroFieldsPassword() {
    this.editForm.get('password').value = ''
    this.editForm.get('confirmPassword').value = ''
    this.editForm.get('currentPassword').value = ''
  }

  //edit Profile
  handleEditProfile() {
    this.show = true
    const nome = this.editForm.get('name').value;
    const telefone = this.editForm.get('phone').value;
    const senha = this.editForm.get('password').value;
    const confirmarSenha = this.editForm.get('confirmPassword').value;
    const currentPassword = this.editForm.get('currentPassword').value;

    const userLocalstorge: any = localStorage.getItem('@OCT:USER')
    const userParse: UserLocalStorageData = JSON.parse(userLocalstorge)
    // motando BODY
    let data = {}
    let bodyPassword: any = {}

    if(!!nome)
      data = {
        ...data,
        nome: nome
      }

    if(!!telefone) {
      data = {
        ...data,
        app_token_notificacao: userParse.notificar_no_app,
        telefones_attributes: [
          {
            id: userParse.telefones[0].id,
            numero: telefone,
            tipo: userParse.telefones[0].tipo,
          }
        ]
      }
    }

    if(!!senha)
      bodyPassword = {
        ...bodyPassword,
        password: senha
      }

    if(!!confirmarSenha)
      bodyPassword = {
        ...bodyPassword,
        ['password_confirmation']: confirmarSenha
      }

    // if(!!currentPassword)
    //   bodyPassword = {
    //     ...bodyPassword,
    //     ['current_password']: currentPassword
    //   }


    if(this.photoUploaded.preview) {
      data = {
        ...data,
      }
    }

    const isFieldsPassword = !bodyPassword?.password && !bodyPassword?.password_confirmation && !bodyPassword?.current_password

    // edita dados usuario
    this.handleRequestEditProfile(data, isFieldsPassword)
    // edita password do usuario
    if(!isFieldsPassword) {
      this.isErroReqPassword = false

      this.userService.newPasswordEdit(bodyPassword)
      .subscribe(
        res => {
          this.labelAlertSuccess = 'Dados alterados com sucesso!'
          this.handleZeroFieldsPassword()
          this.show = false
          setTimeout(() => {
            this.labelAlertSuccess = ''
          }, 2000)
        },
        error => {
          this.errorReq = 'Ocorreu um erro ao alterar a senha! Verique os campos e tente novamente'
          this.isErroReqPassword = true
          setTimeout(() => {
            this.errorReq  = ''
          }, 2000)
        }
      )
    }
  }

  handleEditPhotoUser(e: any) {
    const file = e.target.files[0]
    const formData: FormData = new FormData();
    formData.append('avatar', file)

    const propsUploaded = {
      file: formData,
      name: file.name,
      preview: URL.createObjectURL(file)
    }

    this.photoUploaded = propsUploaded

    this.handleRequestEditProfile(formData)
  }

}
