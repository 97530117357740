import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GuiaService } from '../../utils/services/guia.service';

@Component({
  selector: 'app-archived-guides',
  templateUrl: './archived-guides.component.html',
  styleUrls: ['./archived-guides.component.scss']
})
export class ArchivedGuidesComponent implements OnInit {
  listWaitingOption: any = []
  data = 'asdasd'
  loading = true

  constructor(
    private route: ActivatedRoute,
    private guiaService: GuiaService
  ) { }

  ngOnInit(): void {
   if(this.listWaitingOption.length === 0) {
    this.guiaService.handleGetReqWaitingOption().subscribe(res => {
      this.listWaitingOption = res.body
      this.loading = false
    })
   }
  }
}
