<div class="" [class]="showAccordion ? 'containerCardHistory activedAccordion' : 'containerCardHistory'">
  <header>
    <div class="headerTop">
      <h2>{{nameProcedimento || 'Procedimento não informado'}}</h2>
       <button type="button" (click)="onActivedAccordion()">
          <img src="../../../../assets/imgs/mai-ic-chevron-expand.svg" alt="">
       </button>
    </div>
    <div class="headerBottom">
      <div *ngIf="request.resposta === 'aprovada'">
        <app-tag-card
          type="success"
          label="Procedimento autorizado"
        ></app-tag-card>
      </div>

      <div *ngIf="request.resposta === 'recusada'">
        <app-tag-card
          type="error"
          label="Procedimento negado"
        ></app-tag-card>
      </div>

      <div *ngIf="request.resposta === 'pendente'">
        <app-tag-card
          type="pending"
          label="Procedimento pendente"
        ></app-tag-card>
      </div>

      <!-- <div *ngIf="request.resposta === 'pendente'">
        <app-tag-card
          type="info"
          label="Regulação automática"
        ></app-tag-card> -->
      <!-- </div> -->
    </div>
  </header>

  <div class="contentParams">
    <div class="order-infos__inf" *ngFor="let params of restParams">
      <p>{{params.nome}}: <strong>{{params.valor}}</strong></p>
    </div>
  </div>

  <div class="containerButtonShow">
    <mai-btn
      type='text'
      label="visualizar guia completa"
      (clicked)="handlePush()"
    ></mai-btn>
  </div>
</div>

<!-- MODAL -->
<mai-modal class="modalHistory" *ngIf='show' (clickWrapper)='show = !show;' label='' title=''>
  <div class="modalContent">
    <div class="container_lottie" *ngIf="loading">
      <oct-loading></oct-loading>
    </div>
  </div>

  <!-- DADOS DA GUIA -->
  <div class="containerModalContent" *ngIf="!loading">
    <div class="details">
      <div class="headerContainerModalContent">
        <h2>{{headerLeft}}</h2>
      </div>

      <div *ngIf="arrayAlertDetails.length > 0">
        <div class="container_alert" *ngFor="let alert of arrayAlertDetails" >
          <div class="conteiner_alert__icon">
            <img src="../../../../assets/icons/danger-white.svg" alt="">
          </div>
          <div class="conteiner_alert__content">
            <p>{{alert}}</p>
          </div>
        </div>
      </div>

      <div class="order-infos">
        <p class="title">INFORMAÇÕES DO PEDIDO</p>

        <div class="order-infos__inf" >
          <div *ngFor="let param of params">
            <p>{{param.rotulo}}</p>
            <strong>{{param.valor}}</strong>
          </div>
        </div>
      </div>

      <div class="clinical-condition">
        <p class="title">QUADRO CLINICO</p>
        <strong>{{detailsGuide.quadro_clinico}}</strong>
      </div>

      <div class="container-attchments" *ngIf="anexos.length > 0">
        <p class="title">ANEXOS</p>
        <div class="attchments">
          <app-small-card
            *ngFor="let anexo of anexos"
            [anexo]="anexo"
          >
          </app-small-card>
        </div>
      </div>

      <app-timeline-order *ngIf="historico.length > 0" [history]="historico"></app-timeline-order>

      <div class="history" *ngIf="detailsGuide.historico.length > 0">
        <p class="title">HISTÓRICO DO BENEFICIÁRIO</p>
        <mai-searchbar placeholder="Digite o nome do procedimento"><input (input)="handleSearchHistoric($event)"/></mai-searchbar>

        <!-- // LISTAGEM DE TODAS AS GUIAS REGULADAS -->
        <div class="history_guia" *ngIf="!consultStringSeatch">
          <div class="container_main_guia" *ngFor="let guide of historicoPagination">
            <p class="title">GUIA {{guide.codigo_no_cliente}}</p>
             <app-container-guia *ngFor="let req of guide.solicitacoes" [solicitation]="req"></app-container-guia>
          </div>
        </div>

        <!-- LISTAGEM DAS GUIAS DA PESQUISA -->
        <div class="history_guia" *ngIf="!!consultStringSeatch">
          <div class="container_main_guia" *ngFor="let guide of searchArrayHistory">
            <p class="title">GUIA {{guide.codigo_no_cliente}}</p>
             <app-container-guia *ngFor="let req of guide.solicitacoes" [solicitation]="req"></app-container-guia>
          </div>

          <div class="results-not-found" *ngIf="searchArrayHistory.length === 0">
            <div class="container_illustration_not">
              <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
            </div>
            <div class="content_modal_rest">
              <h2 class="block">Não encontramos nenhuma guia</h2>
              <p class="block">Não há nenhuma guia para está pesquisa</p>
            </div>
          </div>
        </div>
      </div>

      <div class="loading__more" *ngIf="historicoPagination.length !== totalItensHistoricoNumber && !consultStringSeatch">
        <mai-btn type='text' label="carregar mais itens" (click)="nextItensHistory()"></mai-btn>
      </div>
    </div>

    <div class="painel-main">
        <div class="headerContainerModalContent">
          <h2>Solicitações reguladas</h2>
          <button type="button" (click)="show = !show">
            <img src="../../../../../assets/icons/mai-ic-close.mono.svg" alt="x">
          </button>
        </div>
        <div class="container-type-request">
          <app-container-guia
            *ngFor="let solicitation of detailsGuide.solicitacoes"
            [solicitation]="solicitation"
          ></app-container-guia>
        </div>
    </div>
  </div>
</mai-modal>
