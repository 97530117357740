<div class="container_history">
  <h2>Histórico</h2>
  <div class="container_searchbar">
    <mai-searchbar placeholder="Buscar no histórico">
      <input (input)="handleKeyUp($event)" [value]="inputSearch"/>
    </mai-searchbar>
  </div>

  <div class="container_history_type scroll">
    <div class="container_loading">
      <div class="loading" *ngIf="loading || loadingSearch">
        <oct-loading></oct-loading>
      </div>
    </div>


    <!-- INICIO GUIA DA SEARCHBAR -->
    <div *ngIf="listHistoryRequestSearchbar.length !== 0  && !loadingSearch && !!inputSearch">
      <div
        class="container_guides"
        *ngFor="let guia of listHistoryRequestSearchbar"
      >
        <p class="container_guides_title">{{guia.titulo}}</p>

        <div class="guides" guide="guia" *ngFor="let request of guia.solicitacoes">
          <oct-card-historic [request]="request" [idGuide]="guia.id"></oct-card-historic>
        </div>
      </div>
    </div>

    <div class="results-not-found" *ngIf="listHistoryRequestSearchbar.length === 0 && !loadingSearch  && !!inputSearch">
        <div class="container_illustration_not">
          <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
        </div>
        <div class="content_modal_rest">
          <h2 class="block">Não encontramos nenhuma guia</h2>
          <p class="block">Não há nenhuma guia aguardando regulação nos contextos selecionados.</p>
        </div>
   </div>

    <!-- FIM GUIAS DA SEARCHBAR -->
      <div
          infiniteScroll
          class="search-results"
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [fromRoot]="true"
          (scrolled)="onScroll()"
          [scrollWindow]="false"
        >
          <div *ngIf="listHistoryRequestSearchbar.length === 0 && !loadingSearch && !inputSearch">
            <div
              class="container_guides"
              *ngFor="let guia of listHistoryRequest"
            >
              <p class="container_guides_title">{{guia.titulo}}</p>

              <div class="guides" guide="guia" *ngFor="let request of guia.solicitacoes">
                <oct-card-historic [request]="request" [idGuide]="guia.id"></oct-card-historic>
              </div>
            </div>
          </div>

          <div class="container_loading">
            <div class="loading" *ngIf="loadingScroll">
              <oct-loading></oct-loading>
            </div>
          </div>
      </div>
</div>
