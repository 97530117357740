<div class="container_timeline">
  <p>HISTÓRICO DO PEDIDO</p>
  <div class="content_timeline" *ngFor="let obs of formatArrayHistory">
    <h3>{{obs.tipo}}</h3>
    <p>{{obs.observacao}}</p>
    <span>{{obs.data | date: 'dd/MM/yyyy, hh:mm:ss'}}</span>
  </div>

  <!-- <div class="content_timeline">
    <h3>Solicitação de justificativa</h3>
    <p>Auditor Hugo Rafaelli solicitou justificativa: encaminhar justificativa e ou plano de tratamento</p>
    <span>03/09/2020 às 08:35:38</span>
  </div> -->
</div>
