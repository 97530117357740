import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GuiaService } from 'src/app/shared/utils/services/guia.service';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ADD_GUILDE } from 'src/app/shared/context/guide/guide.actions';
import { DetectCell } from 'src/app/shared/utils/libs/mobileFunction';

type OverFlowData = {
  label: string;
  icon?: string;
}

@Component({
  selector: 'ap-template-two-columns',
  templateUrl: './template-two-columns.component.html',
  styleUrls: ['./template-two-columns.component.scss']
})
export class TemplateTwoColumnsComponent implements OnInit, AfterViewInit {
  guia$: Observable<any>

  @Input() headerLeft: string = ''
  @Input() headerRight: string = ''
  foto: string = '../../../../assets/imgs/profile.png'
  show = false
  loading = false
  guideID = 0
  errorReq = ''
  reqSuccess = false
  dataOverflow: any = []

  detectedDevice = ''

  constructor(
    private router: Router,
    private nextGuide: Store<GuiaService | any>,
    private guiaService: GuiaService
  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
   }

  ngAfterViewInit() {
    window.addEventListener('resize', this.resizeDispositivo.bind(this))
  }

  ngOnInit(): void {
    const guideAux: any = localStorage.getItem('@OCT:NEXT_GUIDE')
    const guideFormat = JSON.parse(guideAux)

    this.guideID = guideFormat.id


    if(guideFormat.status === 'aguardando_parecer') {
      this.dataOverflow = [{ label: 'Ir para a próxima guia', icon: 'mai-ic-chevron-double-right' }]
    } else {
      this.dataOverflow = [{ label: 'Arquivar guia', icon: 'mai-ic-drilldown' }, { label: 'Ir para a próxima guia', icon: 'mai-ic-chevron-double-right' }]
    }

    this.resizeDispositivo()
  }

  handleBackPage() {
    this.router.navigate(['homepage'])
  }

  resizeDispositivo() {
    this.detectedDevice = DetectCell()
  }

  onClickOverflow(event: OverFlowData): void {
    if(event.label === "Arquivar guia") {
      this.show = !this.show
    } else  if(event.label === "Ir para a próxima guia") {
      this.router.navigate(['feedback'])
    }
  }

  handleArquivarGuia(): void {
    this.loading = true

    this.guiaService
      .arquivarGuia(this.guideID)
      .subscribe(res => {
        this.show = !this.show
        this.reqSuccess = true
        this.loading = false

        setTimeout(() => {
          this.router.navigate(['feedback'])
        }, 2000)
      },
      (err) => {
        this.loading = false
        this.errorReq = 'Aconteceu um erro nos nossos servidores. Tente novamente em instantes'
      }
      )
  }

}
