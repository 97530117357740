import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators'
import { ADD_GUILDE } from 'src/app/shared/context/guide/guide.actions';
import { GuiaService } from 'src/app/shared/utils/services/guia.service';

@Component({
  selector: 'oct-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  guia$: Observable<any>
  debounce: Subject<string> = new Subject<string>()
  query = ''
  foundGuides: any = []
  inputSearchContent = ''
  loadingSearch = false
  selectedItemDropdown = ''

  showModalRedirect = false
  errorReq = ''

  data: any = []

  options: AnimationOptions = {
    path: '../../../../assets/lottie/oct-loading.json',
  };

  constructor(
    private guiaService: GuiaService,
    private nextGuide: Store<GuiaService | any>,
    private router: Router
  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
  }

  ngOnInit(): void {
    this.debounce
      .pipe(debounceTime(300))
      .subscribe( query => {
        this.foundGuides = []

        if(!!query) {
          this.query = query
          this.guiaService
          .handelSearchGuiaQueue(query)
          .subscribe((res: any) => {

          console.log(res)
            if(res.body.length !== 0) {
              res.body.map((guide: any) => {
                this.foundGuides = [
                  ...this.foundGuides,
                  {
                    label: `${guide.codigo_no_cliente} - ${guide.cliente_nome_fantasia}`,
                    id: guide.id,
                  }
                ]
              })
            } else {
              this.foundGuides = []
            }
            this.loadingSearch = false
          })
        }
      })
  }

  focusInput(): void {
    let container: any = document.querySelector('.container_searchbar')
    container.classList.add("isFocused");
  }

  notFocused(): void {
    let container: any = document.querySelector('.container_searchbar')
    container.classList.remove("isFocused");
  }

  handleKeyUp(e: any) {
    this.loadingSearch = true
    this.debounce.next(e.target.value)
    this.inputSearchContent = e.target.value
  }

  handleOnClick(e: any) {
    this.showModalRedirect = true
    this.errorReq = ''
    this.foundGuides = []
    this.inputSearchContent = ''
    this.selectedItemDropdown = e.label

    this.guiaService
      .getOrder(e.id)
      .subscribe(
        (res: any) => {
          if(this.showModalRedirect) {
            this.nextGuide.dispatch(ADD_GUILDE(res.body))

            localStorage.setItem('@OCT:NEXT_GUIDE', JSON.stringify(res.body))

            e.id = res.body.id

            this.router.navigate(['regulacao/detalhes-solicitacao/', e.id])
            this.showModalRedirect = false
          }
        },
          (error) => {
            this.errorReq = 'Aconteceu um erro nos nossos servidores. Tente novamente em instantes'
            this.showModalRedirect = false
          }
      )
  }

}
