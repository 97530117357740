import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RESPONSE_SOLICITATION } from '../../context/guide/guide.actions';
import { GuiaService } from '../../utils/services/guia.service';
import { GuidelineService } from '../../utils/services/guideline.service';


@Component({
  selector: 'app-big-card',
  templateUrl: './big-card.component.html',
  styleUrls: ['./big-card.component.scss']
})
export class BigCardComponent implements OnInit {
  arrayRes$: Observable<any>
  contextResponse: any

  @Input() solicitation: any = []

  alert = false;
  titleSolicitation = 'Procedimento não encontrado'
  restParams: any = []
  ocorrencias: any = []
  formRequestSelected = ''
  formRequestSelectedAux = ''
  paramsEditRegulacao: any = {}
  paramsEditRegulacaoDecimal: any = {}
  showDrawer = false
  arrayGroupMotivos: any = []
  dataSelect: any  = []
  quantidadeDecimal = false
  quantAutorizadaError = ''
  auxArrayMo: any = []
  lastMotivo = ''

  parametroEditavelRecusadoPendente: any

  motivosBusca: any = []
  consultStringSearch = ''


  // var da respota (inputs e selects)
  quat_dos_den_autorizada = 0
  quantAutoString: any = {}
  respostaMotivo = ''
  quantAutorizada: any
  selectedMotivo: any = {
    motivo: ''
  }

  // "debounce"
  time: any = null

  constructor(
    private responseSolicitation: Store<GuiaService | any>,
    private guideService: GuiaService
  ) {
    this.arrayRes$ = this.responseSolicitation.select('arrayResponse')
  }

  ngOnInit(): void {
    // mostrando informacoes para usuario
    this.solicitation.parametros_ordenados.map((params: any) => {
      if(params.nome.indexOf('nome_procedimento') !== -1) {
        this.titleSolicitation = params.valor
      } else  if(params.nome.indexOf('ocorrencias') !== -1) {
        let auxOcorr = JSON.parse(params.valor)
        this.ocorrencias = [
          ...this.ocorrencias,
          ...auxOcorr
        ]
      } else {
        this.restParams = [
          ...this.restParams,
          { ...params }
        ]
      }
    })


    // buscar um parametro que é editavel && do tipo integer (quantidade)
    this.solicitation.parametros_ordenados.map((params: any) => {
      if((params.tipo === 'string' || params.tipo === 'integer') && params.nome === 'quantidade') {
        if(params.editavel) {
          this.paramsEditRegulacao = {
            id: params.id,
            nome: params.nome,
            valor: params.valor,
            rotulo: params.rotulo,
          }

          for(let i = 1; i<= parseInt(params.valor); i++) {
            this.dataSelect = [
              ...this.dataSelect,
              { label: String(i), value: String(i) }
            ]
          }

          this.quantAutoString = this.dataSelect[0]
          this.quat_dos_den_autorizada = this.quantAutoString.label
        } else {
          this.quantAutoString = params.valor
          this.quat_dos_den_autorizada = params.valor
        }

      } else if (params.editavel && params.tipo === 'decimal') { // se nao editavel && decimal

        this.paramsEditRegulacaoDecimal = {
          id: params.id,
          nome: params.nome,
          valor: params.valor,
          rotulo: params.rotulo,
        }
      }
    })
  }

  handeRequest(e: any): any{
    if(e.value === '1' && this.formRequestSelected !== 'pendente') {
      this.formRequestSelected = 'pendente'

      this.respostaMotivo = ''

      this.onSavedResponseGuide()
    } else if(e.value === '2'  && this.formRequestSelected !== 'recusada'){
      this.formRequestSelected = 'recusada'

      this.respostaMotivo = ''

      this.onSavedResponseGuide()
    } else if(e.value === '3' && this.formRequestSelected !== 'aprovada'){
      this.formRequestSelected = 'aprovada'
      this.respostaMotivo = 'Autorizado por auditoria médica'
      this.selectedMotivo = {
        motivo: ''
      }

      this.onSavedResponseGuide()
    } else {
      this.formRequestSelected = ''
      this.respostaMotivo = ''
      this.onSavedResponseGuide()
    }
  }

  handleChangeMotive(aux: any) {
    let responseSolicitation: any = {}

    const filter = this.contextResponse.pedido?.solicitacoes_attributes
          .filter((auxContextRes: any) => this.solicitation.id !== auxContextRes.id)

    if(filter !== undefined) {
      responseSolicitation = {
        parametrosAux: {
          ...aux
        },
        proximo_pedido: false,
        pedido: {
          solicitacoes_attributes: [
            ...filter,
            {
              id: this.solicitation.id,
              regulacoes_attributes: [
                  {
                    tipo_resposta: 'tecnica',
                    resposta: this.formRequestSelected,
                    motivo: '',
                    laudo_id: this.selectedMotivo.laudo_id,
                    id: this.solicitation.regulacao_id,
                  }
              ]
            }
          ]
        }
      }
    } else {
      responseSolicitation = {
        parametrosAux: {},
        proximo_pedido: false,
        pedido: {
          solicitacoes_attributes: [
            {
              id: this.solicitation.id,
              regulacoes_attributes: [
                  {
                    tipo_resposta: 'tecnica',
                    resposta: this.formRequestSelected,
                    motivo: this.respostaMotivo,
                    laudo_id: this.selectedMotivo.laudo_id,
                    id: this.solicitation.regulacao_id,
                  }
              ]
            }
          ]
        }
      }
    }

    this.responseSolicitation.dispatch(RESPONSE_SOLICITATION(responseSolicitation))
  }

  handleReasonRefusedOrPending(aux: any) {
    let responseSolicitation: any = {}

    const filter = this.contextResponse?.pedido?.solicitacoes_attributes
              .filter((auxContextRes: any) => this.solicitation.id !== auxContextRes.id) || []

    responseSolicitation = {
      parametrosAux: {
        ...aux
      },
      proximo_pedido: false,
      pedido: {
        solicitacoes_attributes: [
          ...filter,
          {
            id: this.solicitation.id,
            regulacoes_attributes: [
                {
                  tipo_resposta: 'tecnica',
                  resposta: this.formRequestSelected,
                  motivo: this.respostaMotivo,
                  laudo_id: this.selectedMotivo.laudo_id,
                  id: this.solicitation.regulacao_id,
                }
            ]
          }
        ]
      }
    }

    this.responseSolicitation.dispatch(RESPONSE_SOLICITATION(responseSolicitation))
  }

  handleSelectApproved() {
    let responseSolicitation: any = {}
    // montando parametro de quantidade autorizada
    this.parametroEditavelRecusadoPendente =  {  [`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacao.nome}`]: Number(this.quat_dos_den_autorizada) }

    // verficia se todos os paramentros editaveis estao preenchidos
    if((!!this.respostaMotivo && this.quat_dos_den_autorizada > 0) || (!!this.respostaMotivo && !!this.quantAutorizada && this.quat_dos_den_autorizada > 0)) {

      // caso haja input editavel e decimal, add tmbm a quantidade de autorizada
      if(this.paramsEditRegulacaoDecimal.rotulo !== undefined) {
        this.handleEditalRegulationDecimal()
      }

      const filter = this.contextResponse.pedido?.solicitacoes_attributes
                .filter((auxContextRes: any) => this.solicitation.id !== auxContextRes.id) || []

      responseSolicitation = {
        parametrosAux: {
          ...this.contextResponse.parametrosAux,
          ...this.parametroEditavelRecusadoPendente
        },
        proximo_pedido: false,
        pedido: {
          solicitacoes_attributes: [
            ...filter,
            {
              id: this.solicitation.id,
              regulacoes_attributes: [
                  {
                    tipo_resposta: 'tecnica',
                    resposta: this.formRequestSelected,
                    motivo: this.respostaMotivo,
                    laudo_id: this.selectedMotivo.laudo_id,
                    id: this.solicitation.regulacao_id,
                  }
              ]
            }
          ]
        }
      }
    }
    // envia a resposta da guia para o contexto
    this.responseSolicitation.dispatch(RESPONSE_SOLICITATION(responseSolicitation))
  }

  handleEditalRegulationDecimal() {
    let auxQuantAutorizada = ''

    if(this.quantAutorizada !== undefined) {
      auxQuantAutorizada = this.quantAutorizada.replace(',0', '')
    }

    this.parametroEditavelRecusadoPendente = {
      [`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacao.nome}`]: Number(this.quat_dos_den_autorizada),
      [`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacaoDecimal.nome}`]: auxQuantAutorizada,
    }
  }

  // logica para request
  onSavedResponseGuide(): void {
    // pegando a resposta do context
    this.arrayRes$.subscribe(res => {
      this.contextResponse = res
    })

    if(this.formRequestSelected === 'aprovada') {
      this.handleSelectApproved()
    } else { // caso seja pendente ou recusada
      // detala a quandidade autorizada
      const aux = {...this.contextResponse.parametrosAux}
      delete aux[`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacao.nome}`]
      delete aux[`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacaoDecimal.nome}`]

      if(!!this.respostaMotivo) {
        this.handleReasonRefusedOrPending(aux)
      } else {
        this.handleChangeMotive(aux)
      }
    }

  }


  // controla valores de select e input
  handleSelected(e: any): void {
    this.quat_dos_den_autorizada = e.label
    this.quantAutoString = e

    this.onSavedResponseGuide()
  }

  handleInputReason(e: any): void {
    this.respostaMotivo = e.target.value
    this.onSavedResponseGuide()
  }

  handleQuatAutDecimal(e: any): void {
    if(!!e.target.value) {
      this.quantAutorizada = String(e.target.value)
    }

    const aux = this.quantAutorizada.replace(',', '.')
    const auxParse = parseFloat(aux)

    if(e.target.value.length < 5) {
      this.quantAutorizada = `${this.quantAutorizada},0`
    }


    // verifica se é maior que a quantidade solicitada ou = 0
    if(auxParse > parseFloat(this.paramsEditRegulacaoDecimal.valor)) {
      this.quantAutorizada = `${this.paramsEditRegulacaoDecimal.valor},0`
      this.quantAutorizadaError = `Dosagem autorizada não pode ultrapassar ${this.paramsEditRegulacaoDecimal.valor},0`
    } else if(auxParse === 0) {
      this.quantAutorizadaError = 'Quantidade autorizada deve ser maior que 0'
      this.quantAutorizada = ''
    }

    this.onSavedResponseGuide()
  }


  //FC PARA LISTAR MOTIVOS
  handleListMotivo(typeResponse: string): void {
    this.guideService
      .getMotivos(this.solicitation.id, typeResponse)
      .subscribe(
        res => {
          const response = res

          this.auxArrayMo = []

          response.filter((res: any) => {
            if(res.resposta === typeResponse ) {
              if(this.auxArrayMo.length === 0) {
                this.auxArrayMo = [
                  {
                    nomeGrupo: res.grupo,
                    listaMotivos: [
                          {
                            label: res.descricao,
                            value: res.descricao,
                            id: res.id
                          },
                      ]
                  }
                ]
              } else {
                let filt: any = this.auxArrayMo.filter((gpp: any) => gpp.nomeGrupo === res.grupo)

                let filter = this.auxArrayMo.filter((gpp: any) => gpp.nomeGrupo !== res.grupo)

                if(filt.length > 0) {
                  let aux  = {
                    ...filt[0],
                    listaMotivos: [
                      ...filt[0].listaMotivos,
                      {
                        label: res.descricao,
                        value: res.descricao,
                        id: res.id
                      },
                    ]
                  }


                  this.auxArrayMo = [
                    ...filter,
                    {...aux}
                  ]
                } else {
                  this.auxArrayMo = [
                    ...filter,
                    {
                      nomeGrupo: res.grupo,
                      listaMotivos: [
                            {
                              label: res.descricao,
                              value: res.descricao,
                              id: res.id
                            },
                        ]
                    }
                  ]
                }
            }
          }
          })

          this.arrayGroupMotivos = this.auxArrayMo

          this.showDrawer = !this.showDrawer
        },
        err=>  this.showDrawer = !this.showDrawer
      )
  }

  handleSelectedMotivo(e: any): void {
    this.selectedMotivo = {
      motivo: e.label,
      laudo_id: e.id
    }

    this.respostaMotivo = this.selectedMotivo.motivo

    this.showDrawer = !this.showDrawer

    this.onSavedResponseGuide()
  }

  handleOpenDrawer(response: string) {
    this.handleListMotivo(response)
  }


  //BUSCA DENTRO DO ARRAY DE MOTIVOS
  handleSearchArrayMotivos(e: any): void {
    this.motivosBusca = []

    this.arrayGroupMotivos.map((gp: any, index: number) => {
      gp.listaMotivos.map((motivo: any) => {
        if(
          motivo.label.toLowerCase().indexOf(String(e.target.value).toLowerCase()) > -1
        ) {
          const filter = this.motivosBusca.filter((grup: any) => grup.nomeGrupo !== gp.nomeGrupo)

          this.motivosBusca = [
            ...filter,
            gp
          ]
        }
      })
    })

    this.consultStringSearch = e.target.value
  }
}
