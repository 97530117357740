import { AfterViewInit, Component, ElementRef, Input, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UserService } from 'src/app/shared/utils/services/user.service';

@Component({
  selector: 'oct-guideline',
  templateUrl: './guideline.component.html',
  styleUrls: ['./guideline.component.scss']
})
export class GuidelineTemplateComponent implements OnInit, AfterViewInit {
  data: any = [
    { label:'diretrizes para regulação', value: '1', icon: 'mai-ic-form'}
  ]
  showDropdown = false
  subClick: any = []

  @Input() label: string = "";

  constructor(
    private elementRef: ElementRef,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.elementRef
      .nativeElement.querySelector('.mai-avatar-container').addEventListener('click', this.handleClickAVatar.bind(this))
  }

  handleClickAVatar() {
    this.showDropdown = !this.showDropdown;
    this.observeClick();
  }

  observeClick() {
    const click = fromEvent(document, 'click');
    let considerClick = false;
    this.subClick.push(click.subscribe((e) => {
      if (considerClick) {
        this.showDropdown = false;
        this.unsubscribe();
        return;
      }
      considerClick = true;
    }));
  }

  unsubscribe() {
    this.subClick.forEach((sub: any) => {
      sub.unsubscribe();
    });
    this.subClick = [];
  }

  handleDrawer(e: any) {
    if(e.label === 'Sair') {
      this.userService.logout()
    }
  }
}

