import { Component, OnInit, DoCheck, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { UserValueToken } from '../../../shared/utils/models/user.model'
import { UserService } from '../../../shared/utils/services/user.service'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, DoCheck {

  user: UserValueToken | any

  constructor(
    private userService: UserService
  ) {
    this.user = userService.getUserData()
  }

  ngOnInit(): void {
  }

  ngDoCheck(): void {
    this.user = this.userService.getUserData()
  }
}
