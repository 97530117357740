<div class="containerFeedback"  *ngIf="guideID !== 0">
  <div class="contentFeedback">
    <div class="container_lottie_feedback">
      <ng-lottie [options]="options"></ng-lottie>
   </div>
   <h2>Aguarde</h2>
   <p>Não se preocupe, já estamos te redirecionando para a próxima solicitação.</p>
  </div>
</div>

<div class="containerFeedback" *ngIf="guideID === 0">
  <app-not-found></app-not-found>
</div>

