<div class="container-guia">
<div class="container-card">
  <h3>{{titleSolicitation || 'Procedimento não informado'}}</h3>

  <div class="containerAlert" *ngFor="let ocorr of ocorrencias">
    <app-alert-fixed
      [message]="ocorr"
    ></app-alert-fixed>
  </div>

  <div class="response-guia">
    <div *ngIf="solicitation.resposta === 'aprovada'">
      <app-tag-card
        type="success"
        label="Procedimento autorizado"
      ></app-tag-card>
    </div>

    <div *ngIf="solicitation.resposta === 'recusada'">
      <app-tag-card
        type="error"
        label="Procedimento negado"
      ></app-tag-card>
    </div>

    <div *ngIf="solicitation.resposta === 'pendente'">
      <app-tag-card
        type="pending"
        label="Procedimento pendente"
      ></app-tag-card>
    </div>
  </div>

  <div class="container-infos">
    <div class="order-infos__inf" *ngFor="let params of restParams">
      <p>{{params.rotulo}}: <strong>{{params.valor}}</strong></p>
    </div>
  </div>
</div>

</div>
