<div class="container" *ngIf="detectedDevice === 'DesktopOrTablet'">
  <div class="container__logo">
    <img src="../../../../assets/logos/octopus-white-large.svg" alt="octopus"/>
  </div>

  <div class="container__form">
    <form [formGroup]="forgotPassword" (submit)="handleRedefinir()" *ngIf="stepNow === 'stepEmail'">
      <header class="topHeader">
        <div class="containerTop">
          <button type="button" (click)="redirectLogin()">
            <img src="../../../../assets/icons/arrow-left.svg" alt="">
          </button>
          <mai-header label='Esqueci minha senha'></mai-header>
        </div>
        <div class="containerBottom">
          <p>Insira abaixo o seu e-mail cadastrado no Octopus e nós enviaremos as instruções para redefinir senha.</p>
        </div>
      </header>

      <mai-input
        placeholder="e-mail"
        iconLeft="mai-ic-email"
        [state]="isErrorReqEmail || !!errorEmail ? 'danger' : 'default'"
        [assistiveText]='errorEmail'
      ><input
        #emailInput
        type="email"
        (keyup)="email = emailInput.value"
        formControlName="email"
        (focus)="removeErrorEmail()"
      /></mai-input>

      <mai-btn
        id="btnSign"
        [label]="!loading ? 'recuperar senha' : 'carregando...'"
        [disabled]="(!email) || loading"
      ></mai-btn>

      <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
    </form>

    <form [formGroup]="forgotPassword" (submit)="handleVerifCode()" *ngIf="stepNow === 'stepCode'">
      <header class="topHeader">
        <div class="containerTop">
          <button type="button" (click)="redirectLogin()">
            <img src="../../../../assets/icons/arrow-left.svg" alt="">
          </button>
          <mai-header label='Redefinir senha'></mai-header>
        </div>
        <div class="containerBottom">
          <p>Insira abaixo o código enviado para seu email.</p>
        </div>
      </header>

      <mai-input
        placeholder="Código de recuperação"
        iconLeft="mai-ic-password"
      ><input
        type="text"
        (input)="handleChangeInput($event)"
        formControlName="code"
      /></mai-input>

      <mai-btn
        id="btnSign"
        [label]="!loading ? 'Próximo' : 'carregando...'"
        [disabled]="(!code) || loading"
      ></mai-btn>

      <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
    </form>

    <form [formGroup]="forgotPassword" (submit)="handleNewPassword()" *ngIf="stepNow === 'stepNewPassword'">
      <header class="topHeader">
        <div class="containerTop">
          <button type="button" (click)="redirectLogin()">
            <img src="../../../../assets/icons/arrow-left.svg" alt="">
          </button>
          <mai-header label='Redefinir senha'></mai-header>
        </div>
        <div class="containerBottom">
          <p>Insira abaixo a sua nova senha de acesso no Octopus.</p>
        </div>
      </header>

      <mai-input
        placeholder="Nova senha"
        iconLeft="mai-ic-password"
        [state]="!!errorMathPassword ? 'danger' : 'default'"
      ><input
        type="password"
        (input)="handleChangeSenha($event)"
        formControlName="password"
        (focus)="errorMathPassword = ''"
      /></mai-input>

      <mai-input
        placeholder="Confirmar senha"
        iconLeft="mai-ic-password"
        [state]="!!errorMathPassword ? 'danger' : 'default'"
      ><input
        type="password"
        (input)="handleChangeSenhaConfirm($event)"
        formControlName="confirmationPassword"
        (focus)="errorMathPassword = ''"
      /></mai-input>

      <mai-btn
        id="btnSign"
        [label]="!loading ? 'Cadastrar nova senha' : 'carregando...'"
        [disabled]="(!senha || !senhaConfirm) || loading"
      ></mai-btn>

      <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
    </form>

    <form class="containerFeedback" *ngIf="stepNow === 'stepFeedback'">
      <div class="lottieFeedback">
        <ng-lottie [options]="options"></ng-lottie>
      </div>
      <div class="textFeedback">
        <h2>Senha cadastrada</h2>
        <p>Sua nova senha foi cadastrada, utilize-a no seu próximo acesso.</p>
      </div>
    </form>

    <p>Octopus é um produto <a href="#">maida.health.</a></p>
  </div>
</div>


<div class="device" *ngIf="detectedDevice === 'Android' || detectedDevice === 'Ios'">
  <app-device-redirect [typeDevice]="detectedDevice"></app-device-redirect>
</div>

<!-- ALERT ERROR -->
<mai-alert *ngIf='!!alertMsgError' type='danger' [label]='alertMsgError'></mai-alert>

<!-- ALERT SUCESSO -->
<mai-alert *ngIf='!!alertMsgSuccess' type='success' [label]='alertMsgSuccess'></mai-alert>
