import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AvatarModule,
  BtnCtaModule,
  BtnModule,
  CardModule,
  HeaderModule,
  InputModule,
  ProgressBarModule,
  SearchbarModule,
  SideNavModule,
  TopNavModule,
  RadiobtnModule,
  InfobarModule,
  ReplybtnModule,
  OverflowModule,
  DropdownModule,
  DrawerModule,
  SvgIconComponent,
  SvgIconModule,
  ModalModule,
  SelectModule,
  AlertModule,
  BtnLeftModule
} from 'dls';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    BtnModule,
    InputModule,
    SideNavModule,
    BtnCtaModule,
    HeaderModule,
    TopNavModule,
    CardModule,
    SearchbarModule,
    AvatarModule,
    ProgressBarModule,
    RadiobtnModule,
    InfobarModule,
    ReplybtnModule,
    OverflowModule,
    DropdownModule,
    DrawerModule,
    SvgIconModule,
    ModalModule,
    SelectModule,
    AlertModule,
    BtnLeftModule
  ],
  exports: [
    BtnModule,
    InputModule,
    SideNavModule,
    BtnCtaModule,
    HeaderModule,
    TopNavModule,
    CardModule,
    SearchbarModule,
    AvatarModule,
    ProgressBarModule,
    RadiobtnModule,
    InfobarModule,
    ReplybtnModule,
    OverflowModule,
    DropdownModule,
    DrawerModule,
    SvgIconModule,
    ModalModule,
    SelectModule,
    AlertModule,
    BtnLeftModule
  ]
})
export class ShareComponentModule { }
