import { createReducer, on } from '@ngrx/store';

import { ADD_GUILDE, REMOVE_RESPONSE_SOLICITATION, RESPONSE_SOLICITATION } from './guide.actions';
import { GuildeModel } from './guilde.model';

export const nextGuilde = new GuildeModel();
export const responseSoli: any = {}

export const guildeReducer = createReducer(
  nextGuilde,
  on(ADD_GUILDE, (state: any, action) => {
    state = action

    return state
  })
)

export const responseSolicitationReducer = createReducer(
  responseSoli,
  on(RESPONSE_SOLICITATION, (state: any, action) => {
    state = action
    return state
  }),
  on(REMOVE_RESPONSE_SOLICITATION, (state: any, action) => {
    state = {}

    return state
  }),
)
