<mai-top-nav
  class="topNavGuideline"
  [sideNavData]="{ data: data , logotype: 'octopus', sectionSelected: '1', subSectionSelected: '', isClosed: false }"
  [headerData]='{label: "Diretrizes para regulação", editableTitle: false}'
  [avatarData]="{ photo: 'placeholder', size: 'default' }"
>
  <mai-dropdown
    [data]="[{ label: 'Sair', icon: 'mai-ic-close'}]"
    *ngIf='showDropdown'
    (clicked)="handleDrawer($event)"
    class="dropdownSair"
  ></mai-dropdown>

  <div class="content">
    <ng-content></ng-content>
  </div>
</mai-top-nav>
