export function DetectCell(): string {
  if((navigator.userAgent.match(/Android|Tablet/i)) ) {
    return 'Android'
  } else if(navigator.userAgent.match(/iPhone|iPad/i)) {
    return 'Ios'
  } else if(window.innerWidth < 1025){
    return 'Android'
  }

  return 'DesktopOrTablet'
}
