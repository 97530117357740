<ap-template-two-columns
  [headerLeft]="headerLeft"
  headerRight="Solicitações"
>
    <div class="details">
      <div *ngIf="arrayAlertDetails.length > 0">
        <div class="container_alert" *ngFor="let alert of arrayAlertDetails" >
          <div class="conteiner_alert__icon">
            <img src="../../../../assets/icons/danger-white.svg" alt="">
          </div>
          <div class="conteiner_alert__content">
            <p>{{alert}}</p>
          </div>
        </div>
      </div>

      <div class="order-infos">
        <p class="title">INFORMAÇÕES DO PEDIDO</p>

        <div class="order-infos__inf" >
          <div *ngFor="let param of params">
            <p>{{param.rotulo}}</p>
            <strong>{{param.valor}}</strong>
          </div>
        </div>
      </div>

      <div class="clinical-condition">
        <p class="title">QUADRO CLINICO</p>
        <strong>{{detailsGuide.quadro_clinico}}</strong>
      </div>

      <div class="container-attchments" *ngIf="anexos.length > 0">
        <p class="title">ANEXOS</p>
        <div class="attchments">
          <app-small-card
            *ngFor="let anexo of anexos"
            [anexo]="anexo"
          >
          </app-small-card>
        </div>
      </div>

      <app-timeline-order *ngIf="historico.length > 0" [history]="historico"></app-timeline-order>

      <div class="history" *ngIf="detailsGuide.historico.length > 0">
        <p class="title">HISTÓRICO DO BENEFICIÁRIO</p>
        <mai-searchbar placeholder="Digite o nome do procedimento"><input (input)="handleSearchHistoric($event)"/></mai-searchbar>

        <!-- // LISTAGEM DE TODAS AS GUIAS REGULADAS -->
        <div class="history_guia" *ngIf="!consultStringSeatch">
          <div class="container_main_guia" *ngFor="let guide of historicoPagination">
            <p class="title">GUIA {{guide.codigo_no_cliente}}</p>
             <app-container-guia *ngFor="let req of guide.solicitacoes" [solicitation]="req"></app-container-guia>
          </div>
        </div>

        <!-- LISTAGEM DAS GUIAS DA PESQUISA -->
        <div class="history_guia" *ngIf="!!consultStringSeatch">
          <div class="container_main_guia" *ngFor="let guide of searchArrayHistory">
            <p class="title">GUIA {{guide.codigo_no_cliente}}</p>
             <app-container-guia *ngFor="let req of guide.solicitacoes" [solicitation]="req"></app-container-guia>
          </div>

          <div class="results-not-found" *ngIf="searchArrayHistory.length === 0">
            <div class="container_illustration_not">
              <img src="../../../../assets/images/illustrations/results-not-found.svg" alt="">
            </div>
            <div class="content_modal_rest">
              <h2 class="block">Não encontramos nenhuma guia</h2>
              <p class="block">Não há nenhuma guia para está pesquisa</p>
            </div>
          </div>
        </div>
      </div>

      <div class="loading__more" *ngIf="historicoPagination.length !== totalItensHistoricoNumber && !consultStringSeatch">
        <mai-btn type='text' label="carregar mais itens" (click)="nextItensHistory()"></mai-btn>
      </div>
    </div>

    <!-- LADO DIREITO DA PAGE (SOLICITACOES A SEREM RESPONDIDAS) -->
    <div class="painel-main">
       <div class="container-type-request">
         <app-card-ia
          *ngFor="let solicitation of arraySolicitaionsIA"
          [solicitation]="solicitation"
         ></app-card-ia>
          <app-big-card
            *ngFor="let solicitation of arraySolicitaionsNoIA"
            [solicitation]="solicitation"
          ></app-big-card>
       </div>
       <div class="painel-main__buttons">
         <mai-btn
          [label]="loadingReq ? 'enviando resposta...' : 'enviar respostas'"
          (clicked)="onRegularOrder()"
          [disabled]="detailsGuide.solicitacoes.length !== listItensRegulados.length || loadingReq"
        ></mai-btn>
       </div>
    </div>
</ap-template-two-columns>


<!-- //ALERT -->
<mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
