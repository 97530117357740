import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Store, select } from '@ngrx/store';

import { GuiaService } from '../../utils/services/guia.service';
import { Observable } from 'rxjs';
import { ADD_GUILDE } from '../../context/guide/guide.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  @Input() name = ''
  guia$: Observable<any>
  guiaId: number = 1

  show = false
  errorReq = ''
  searchData: any =[]
  options: AnimationOptions = {
    path: '../../../../assets/lottie/octopus-search.json',
  };

  constructor(
    private guiaService: GuiaService,
    private nextGuide: Store<GuiaService | any>,
    private router: Router
  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
  }

  ngOnInit(): void {
  }

  handleSearchData() {
    this.show = !this.show
    this.errorReq = ''
    this.guiaId = 1

    if(this.show) {
      this.guiaService
      .handleGetNextGuide()
      .subscribe(
        (res: any) => {

          if(this.show &&  res.body.id > 0) {
            this.guiaId = res.body.id
            this.nextGuide.dispatch(ADD_GUILDE(res.body))

            localStorage.setItem('@OCT:NEXT_GUIDE', JSON.stringify(res.body))
            this.router.navigate(['regulacao/detalhes-solicitacao/', this.guiaId])

            this.show = !this.show
          } else {
            this.guiaId = 0
          }

        },
        (error) => {
          this.errorReq = 'Aconteceu um erro nos nossos servidores. Tente novamente em instantes'
          this.guiaId = 0
          this.show = !this.show
        }
        )
    }
  }

  animationCreated(animationItem: AnimationItem): void {
  }

  handleCancelReq() {
    this.show = !this.show
  }
}
