<div class="containerMain">
  <p class="title">{{solicitation.tipo_item}} / {{solicitation.tipo_evento.descricao}}</p>
  <div class="container-card">
    <h3>{{titleSolicitation || 'Procedimento não informado'}}</h3>
    <div class="container-tags">
      <app-tag-card
        type="success"
        [label]="labelIAReq"
      ></app-tag-card>
      <app-tag-card
        type="info"
        label="Regulação automática"
      ></app-tag-card>
    </div>
    <div class="container-pdfs">
      <mai-btn iconLeft='../../../../assets/icons/pdf.svg' type="text" theme='info' label="VISUALIZAR DIRETRIZ"></mai-btn>
    </div>
    <div class="containerAlert" *ngFor="let ocorr of ocorrencias">
      <app-alert-fixed
        [message]="ocorr"
      ></app-alert-fixed>
    </div>
    <div class="container-infos">
      <div class="order-infos__inf" *ngFor="let params of restParams">
        <p>{{params.rotulo}}: <strong>{{params.valor}}</strong></p>
      </div>
    </div>
  </div>
</div>
