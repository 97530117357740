import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { TokenService } from '../token/token.service';
import { UserService } from 'src/app/shared/utils/services/user.service';


@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor (
    private tokenService: TokenService,
    private userService: UserService
  ) {

  }

 intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {

  const isToken = this.tokenService.hasToken()
  const headersRes  = this.tokenService.getFullHeaders()

  if(isToken) {
    request = request.clone({
      headers: new HttpHeaders({
        'Access-Token': headersRes?.token,
        'Client': headersRes.client,
        'uid': headersRes.uid
      })
    })
    // logout automatico caso token tenha expirado
    next.handle(request).subscribe(res => {}, err => {
      if(err.status === 401) {
        this.userService.logout()
      }
    })
  }
  return next.handle(request);
 }
}
