import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store'
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './modules/RegulatorDoctor/signin/signin.component';
import { ShareComponentModule } from './shared/dls/share-component.module';
import { DetailsRequestComponent } from './modules/RegulatorDoctor/details-request/details-request.component';
import { TemplateTwoColumnsComponent } from './core/layouts/template-two-columns/template-two-columns.component';
import { BigCardComponent } from './shared/components/big-card/big-card.component';
import { SmallCardComponent } from './shared/components/small-card/small-card.component';
import { HomepageComponent } from './modules/RegulatorDoctor/homepage/homepage.component';
import { TemplateOneColumnComponent } from './core/layouts/template-one-column/template-one-column.component';
import { ContainerGuiaComponent } from './shared/components/container-guia/container-guia.component';
import { ArchivedGuidesComponent } from './shared/components/archived-guides/archived-guides.component';
import { SearchBoxComponent } from './shared/components/search-box/search-box.component';
import { HistoryBoxComponent } from './shared/components/history-box/history-box.component';
import { CardComponent } from './shared/components/history-box/card/card.component';
import { GuideComponent } from './shared/components/archived-guides/guide/guide.component';
import { AlertFixedComponent } from './shared/components/alert-fixed/alert-fixed.component';
import { TimelineOrderComponent } from './shared/components/timeline-order/timeline-order.component';
import { InterceptorModule } from './core/auth/interceptor/interceptor.module';
import { LoadingInputComponent } from './shared/components/loading-input/loading-input.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { SearchBarComponent } from './shared/components/search-box/search-bar/search-bar.component';
import { guildeReducer, responseSolicitationReducer } from './shared/context/guide/guilde.reducer';
import { DropdownComponent } from './shared/components/dropdown/dropdown.component';
import { GuidelineTemplateComponent } from './core/layouts/guideline/guideline.component';
import { GuidelineComponent } from './modules/Manager/guideline/guideline.component';
import { FeedbackComponent } from './modules/RegulatorDoctor/feedback/feedback.component';
import { FeedbackNoSendComponent } from './modules/RegulatorDoctor/feedback-no-send/feedback.component';
import { DeviceRedirectComponent } from './shared/components/device-redirect/device-redirect.component';
import { CardHistoricComponent } from './shared/components/card-historic/card-historic.component';
import { ForgotPasswordComponent } from './modules/RegulatorDoctor/forgot-password/forgot-password.component';
import { CardIaComponent } from './shared/components/card-ia/card-ia.component';
import { TagCardComponent } from './shared/components/tag-card/tag-card.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';


export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    DetailsRequestComponent,
    TemplateTwoColumnsComponent,
    BigCardComponent,
    SmallCardComponent,
    HomepageComponent,
    TemplateOneColumnComponent,
    ContainerGuiaComponent,
    ArchivedGuidesComponent,
    SearchBoxComponent,
    HistoryBoxComponent,
    CardComponent,
    GuideComponent,
    AlertFixedComponent,
    TimelineOrderComponent,
    LoadingInputComponent,
    LoadingComponent,
    SearchBarComponent,
    DropdownComponent,
    GuidelineTemplateComponent,
    GuidelineComponent,
    FeedbackComponent,
    FeedbackNoSendComponent,
    DeviceRedirectComponent,
    CardHistoricComponent,
    ForgotPasswordComponent,
    CardIaComponent,
    TagCardComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ShareComponentModule,
    LottieModule.forRoot({ player: playerFactory }),
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    InterceptorModule,
    InfiniteScrollModule,
    StoreModule.forRoot({
      nextGuilde: guildeReducer,
      arrayResponse: responseSolicitationReducer
    }),
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
