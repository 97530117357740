import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-fixed',
  templateUrl: './alert-fixed.component.html',
  styleUrls: ['./alert-fixed.component.scss']
})
export class AlertFixedComponent implements OnInit {
  @Input() message: any = ''

  constructor() { }

  ngOnInit(): void {
  }

}
