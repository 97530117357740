<div class="container_guide" (click)="handlePush()">
  <h3>{{guide.codigo_no_cliente}} • {{guide.cliente_nome_fantasia}}</h3>
  <p>{{guide.tipo_evento_descricao}}</p>
  <div class="type_guide">
    <p>Guia atrasada em {{delayedGuideTime}}</p>
  </div>
</div>

   <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>

<mai-modal class="modalRedirect" *ngIf='showModalRedirect' (clickWrapper)='showModalRedirect = !showModalRedirect;' label='' title=''>
  <div class="contentModalRedirect">
    <ng-lottie [options]="options"></ng-lottie>
  </div>
</mai-modal>
