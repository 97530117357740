<div class="archived">
  <div class="archived_title">
    <p>GUIAS ARQUIVADAS</p>
  </div>
  <div class="archived_content">
    <div class="container_loading">
      <div class="loading" *ngIf="loading">
        <oct-loading></oct-loading>
      </div>
    </div>

    <div class="archived_content_is_empty" *ngIf="listWaitingOption.length === 0 && !loading">
      <img src="../../../../assets/images/illustrations/homepage-archived.svg" alt="">
      <p>Você não arquivou nenhuma guia ainda</p>
    </div>

    <div class="archived_content_not_empty">
      <div class="container_guide" *ngFor="let guide of listWaitingOption">
        <app-guide [guide]="guide"></app-guide>
      </div>
    </div>
  </div>
</div>
