import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-guia',
  templateUrl: './container-guia.component.html',
  styleUrls: ['./container-guia.component.scss']
})
export class ContainerGuiaComponent implements OnInit {
  // @Input() typeResponse = ''
  // @Input() alert = false

  @Input() solicitation: any = []

  titleSolicitation = ''
  ocorrencias: any = []
  restParams: any = []

  constructor() { }

  ngOnInit(): void {
    this.solicitation.parametros_ordenados.map((params: any) => {
      if(params.nome.indexOf('nome_procedimento') !== -1) {
        this.titleSolicitation = params.valor
      } else  if(params.nome.indexOf('ocorrencias') !== -1) {
        let auxOcorr = JSON.parse(params.valor)
        this.ocorrencias = [
          ...this.ocorrencias,
          ...auxOcorr
        ]
      }  else {
        this.restParams = [
          ...this.restParams,
          { ...params }
        ]
      }
    })
  }

}
