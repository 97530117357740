import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GuiaService } from '../../utils/services/guia.service'

@Component({
  selector: 'app-history-box',
  templateUrl: './history-box.component.html',
  styleUrls: ['./history-box.component.scss']
})
export class HistoryBoxComponent implements OnInit {
  debounce: Subject<string> = new Subject<string>()
  listHistoryRequest: any
  listHistoryRequestSearchbar: any = []
  listNextPage: any
  selector: any
  loading = true
  loadingScroll = false
  query = ''
  foundGuides: any = []
  inputSearchContent = ''
  loadingSearch = false
  inputSearch = ''


  constructor(
    private route: ActivatedRoute,
    private guiaService: GuiaService
  ) { }

  ngOnInit(): void {
    this.selector = document.querySelector('.search-results')

    if(!this.listHistoryRequest) {
      this.guiaService.handleGetHistory().subscribe(res => {
        this.listHistoryRequest = res.body?.pedidos
        this.loading = false
      })
    }

    //logica busca no historico
    this.debounce
      .pipe(debounceTime(1200))
      .subscribe( query => {
        this.query = query
        this.loadingSearch = true
        this.listHistoryRequestSearchbar = []
        this.inputSearch = query

        this.guiaService
        .handleSearchGuiaResquest(query)
        .subscribe((res: any) => {
          if(!!query) {
            if(res.body?.pedidos.length !== 0) {
              this.listHistoryRequestSearchbar = res.body?.pedidos
            } else {
              this.listHistoryRequestSearchbar = []
            }
          }
          this.loadingSearch = false
        })
      },
      error => {
        this.loadingSearch = false
      }
      )

  }

  onScroll() {
    this.loadingScroll = true


    this.guiaService
      .handleGetNextPageRequests()
      .subscribe(res => {
        this.listNextPage = res.body?.pedidos
        this.listHistoryRequest = this.listHistoryRequest.concat(this.listNextPage)

        this.loadingScroll = false
      },
      (err) => {
        console.log(err)
      }
      )
  }

  handleKeyUp(e: any) {
    this.loadingSearch = true
    this.debounce.next(e.target.value)
    this.inputSearchContent = e.target.value
  }

}
