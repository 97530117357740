import { Component, Input, OnInit } from '@angular/core';

type TagCardData = 'success' | 'info' | 'error' | 'pending' | any


@Component({
  selector: 'app-tag-card',
  templateUrl: './tag-card.component.html',
  styleUrls: ['./tag-card.component.scss']
})
export class TagCardComponent implements OnInit {
  @Input() type: TagCardData
  @Input() label = ''
  typeDefault = ''

  constructor() { }

  ngOnInit(): void {
    this.typeDefault = `containerTagCard ${this.type}`
  }

}
