<div class="containerDeviceRedirect">
  <div class="content">
    <div class="containerImgs">
      <img src="../../../../assets/images/logo.svg" alt="Octopus">
      <img src="../../../../assets/images/illus-redirect.svg" alt="Ilustração mobile">
    </div>
    <div class="containerText">
      <h2>Experimente nosso aplicativo</h2>
      <p>Faça suas regulações através do aplicativo, com muito mais facilidade.</p>
    </div>
    <a href="https://play.google.com/store/apps/details?id=com.infoway.octopus">
      <button class="btnRedirect" *ngIf="typeDevice === 'Android'">
        <img src="../../../../assets/images/android-redirect.png" alt="Ir a play store">
      </button>
    </a>
   <a href="https://apps.apple.com/us/app/octopus-regula%C3%A7%C3%A3o-e-auditoria/id1535796013">
    <button class="btnRedirect"  *ngIf="typeDevice === 'Ios'">
      <img src="../../../../assets/images/ios-redirect.png" alt="Ir a app store">
    </button>
   </a>
  </div>
</div>
