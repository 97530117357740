import { Component, Input, OnInit } from '@angular/core';

interface HistoryData {
  id: number;
  nome: string;
  tipo: string;
  valor: string;
  formatData?: Object;
}

interface ArrayHistoryData {
  tipo: string;
  observacao: string;
  data: string;
}

@Component({
  selector: 'app-timeline-order',
  templateUrl: './timeline-order.component.html',
  styleUrls: ['./timeline-order.component.scss']
})
export class TimelineOrderComponent implements OnInit {
  @Input() history: HistoryData[] = []
  formatArrayHistory: ArrayHistoryData[] = []

  constructor() { }

  ngOnInit(): void {
    let aux: any = []

    this.history.map((his: HistoryData) => {
      if(his.valor) {
        aux = [
          ...aux,
          {
            formatData: JSON.parse(his.valor)
          }
        ]
      }
    })

    this.formatArrayHistory = [...aux[0].formatData]
  }

}
