import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { ADD_GUILDE } from 'src/app/shared/context/guide/guide.actions';
import { GuiaService } from 'src/app/shared/utils/services/guia.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent implements OnInit {
  guia$: Observable<any>
  @Input() guide: Object | any = {}
  delayedGuideTime = ''
  guideID = 0

  showModalRedirect = false
  errorReq = ''

  options: AnimationOptions = {
    path: '../../../../assets/lottie/oct-loading.json',
  };

  constructor(
    private router: Router,
    private nextGuide: Store<GuiaService | any>,
    private guiaService: GuiaService
  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
  }

  ngOnInit(): void {
    this.handleSetDateTimestamp(this.guide?.prazo_para_regulacao)
    this.guideID = this.guide.id
  }

  handleSetDateTimestamp(date: any) {
    const typeGuide = document.querySelectorAll('.type_guide')
    //tranformando data(prazo_para_regulacao) em timestamp
    const transformDate = Date.parse(date)
    const porcentDateDanger = transformDate * 0.2
    const porcentDateWarngin = transformDate * 0.5

    //transformando data atual em timestamp
    const today: any = new Date()
    const tranformToday = Date.parse(today)

    const difeDates =  tranformToday - transformDate


    if(difeDates <= porcentDateDanger) {
      typeGuide.forEach(element => {
        element.classList.add('danger')
      });

      this.delayedGuideTime = this.delayedGuideTimeToDays(difeDates)

    } else if(difeDates >= porcentDateDanger || difeDates <= porcentDateWarngin) {
      typeGuide.forEach(element => {
        element.classList.add('')
      });
    }
  }

  delayedGuideTimeToDays(difeDates: any): string {
    const segundos = Math.floor(difeDates / 1000)
    const dias = Math.floor(segundos / 86400)
    let res_segund = segundos % 86400
    const horas = Math.floor(res_segund / 3600)
    res_segund = res_segund % 3600
    const minutos = Math.floor(res_segund / 60)

    if(dias === 0) {
      return `${horas}h e ${minutos}m`
    } else if(dias === 0 && horas ===0) {
      return `${minutos}m`
    } else {
      return `${dias}d, ${horas}h e ${minutos}m`
    }
  }

  handlePush(): void {
    this.showModalRedirect = true
    this.errorReq = ''

    this.guiaService
      .getOrder(this.guideID)
      .subscribe(
        (res: any) => {
          if(this.showModalRedirect) {
            this.nextGuide.dispatch(ADD_GUILDE(res.body))

            localStorage.setItem('@OCT:NEXT_GUIDE', JSON.stringify(res.body))

            this.guideID = res.body.id

            this.router.navigate(['regulacao/detalhes-solicitacao/', this.guideID])
            this.showModalRedirect = false
          }
        },
          (error) => {
            this.guideID = 0
            this.errorReq = 'Aconteceu um erro nos nossos servidores. Tente novamente em instantes'
            this.showModalRedirect = false
          }
      )
  }

}
