import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DetectCell } from 'src/app/shared/utils/libs/mobileFunction';
import { UserService } from 'src/app/shared/utils/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string = ''
  senha: string = ''
  senhaConfirm = ''
  disabled: boolean = true

  stepNow = 'stepEmail'

  forgotPassword: FormGroup | any;
  errorEmail: string = ''
  errorCode: string = ''
  errorMathPassword = ''
  isErrorReqEmail = false
  isErrorReqPassword = false
  errorReq = ''
  loading = false

  alertMsgError = ''
  alertMsgSuccess = ''

  detectedDevice = ''

  code = ''

  options: AnimationOptions = {
    path: '../../../../assets/lottie/oct-success.json',
  };

  @ViewChild('emailInput') emailInputElement: ElementRef<HTMLInputElement> | undefined

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.forgotPassword = this.formBuilder.group({
      email: ['', Validators.email],
      code: ['', Validators.required],
      password: ['', Validators.required],
      confirmationPassword: ['', Validators.required]
    })

    this.resizeDispositivo()
  }

  resizeDispositivo() {
    this.detectedDevice = DetectCell()
  }

  ngAfterViewInit() {
    window.addEventListener('resize', this.resizeDispositivo.bind(this))
  }


  handleRedefinir(): void {
    this.isErrorReqEmail = false
    this.errorEmail = this.forgotPassword.get("email").errors?.email ? 'Email é invalido' : ''

    if(!this.errorEmail){
      this.loading = true

      const email = this.forgotPassword.get('email').value;

      this.userService
        .sendEmailCode(email)
        .subscribe(
          (res) => {
            this.alertMsgSuccess = res.message

            setTimeout(() => {
              this.stepNow = 'stepCode'
              this.alertMsgSuccess = ''
              this.loading = false
            }, 3000)
          },
          (err) => {
            this.alertMsgError = 'Crendenciais Inválidas! Verifique suas credenciais ou tente novamente mais tarde.'
            this.loading = false

            setTimeout(() => {
              this.alertMsgError = ''
            }, 3000)
          }
        )
    }
  }

  handleVerifCode(): void {
    this.errorCode = this.forgotPassword.get("code").errors?.code ? 'Código obrigátorio! Verifique no seu email.' : ''

    if(!this.errorEmail){
      this.loading = true

      const code = this.forgotPassword.get('code').value;

      this.code = code

      this.userService
        .autenticateCodeResetPassword(code)
        .subscribe(
          (res) => {
            this.alertMsgSuccess = 'Código confirmado com sucesso! Estamos redirecionando para cadastrar nova senha.'

            setTimeout(() => {
              this.stepNow = 'stepNewPassword'
              this.alertMsgSuccess = ''
              this.loading = false
            }, 3000)
          },
          (err) => {
            this.alertMsgError = 'Código inválido! Verifique suas credenciais ou tente novamente mais tarde.'
            this.loading = false

            setTimeout(() => {
              this.alertMsgError = ''
            }, 3000)
          }
        )
    }
  }

  handleNewPassword(): void {
    const password = this.forgotPassword.get('password').value;
    const confirmPassword = this.forgotPassword.get('confirmationPassword').value;


    const mathPassword = password === confirmPassword

    if(mathPassword){
      this.userService
        .resetNewPassword({
          code: this.code,
          password,
          confirmPassword
        })
        .subscribe(
          (res) => {
            this.stepNow = 'stepFeedback'

            setTimeout(() => {
              this.redirectLogin()
              this.stepNow = 'stepEmail'
            }, 4000)
          },
          (err) => {
            this.alertMsgError = 'Crendenciais Inválidas! Verifique suas credenciais ou tente novamente mais tarde.'
            this.loading = false

            setTimeout(() => {
              this.alertMsgError = ''
            }, 3000)
          }
        )
    } else {
      this.errorMathPassword = 'Senhas são diferentes! Verique sua senha ou tente novamente mais tarde.'
      this.alertMsgError = 'Senhas são diferentes! Verique sua senha ou tente novamente mais tarde.'
      this.loading = false
    }
  }

  redirectLogin() {
    this.router.navigate([''])
  }


  removeErrorEmail(): any {
    if(this.isErrorReqEmail) {
      this.isErrorReqEmail = false
    }

  }


  handleChangeInput(e: any) {
    this.code = e.target.value
  }

  handleChangeSenha(e: any) {
    this.senha = e.target.value
  }

  handleChangeSenhaConfirm(e: any) {
    this.senhaConfirm = e.target.value
  }

}
