<div class="container_search">
  <div class="container_searchbar--search">
    <img src="../../../../assets/icons/search-white.svg" alt="" />
    <input
      type="text"
      placeholder="Buscar na fila"
      (focus)="focusInput()"
      (focusout)="notFocused()"
      (input)="handleKeyUp($event)"
      [value]="selectedItemDropdown"
    />
  </div>

  <div class="container_searchbar__dropdown">
    <div class="container_dropdown" *ngIf="!loadingSearch && foundGuides.length > 0">
      <mai-dropdown
        [data]="foundGuides"
        (clicked)="handleOnClick($event)"
      ></mai-dropdown>
    </div>

    <oct-dropdown *ngIf="loadingSearch && inputSearchContent.length > 0" [data]="[{ titulo: 'buscando...' }]"></oct-dropdown>
    <oct-dropdown *ngIf="!loadingSearch && foundGuides.length === 0 && inputSearchContent.length > 0" [data]="[{ titulo: 'Nenhuma guia encontrada.' }]"></oct-dropdown>
    <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
  </div>
</div>

<!-- MODAL -->
<mai-modal class="modalRedirect" *ngIf='showModalRedirect' (clickWrapper)='showModalRedirect = !showModalRedirect;' label='' title=''>
  <div class="contentModalRedirect">
    <ng-lottie [options]="options"></ng-lottie>
  </div>
</mai-modal>

