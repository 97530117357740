import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs';
import { REMOVE_RESPONSE_SOLICITATION, RESPONSE_SOLICITATION } from 'src/app/shared/context/guide/guide.actions';
import { GuiaService } from 'src/app/shared/utils/services/guia.service';

@Component({
  selector: 'ap-details-request',
  templateUrl: './details-request.component.html',
  styleUrls: ['./details-request.component.scss']
})
export class DetailsRequestComponent implements OnInit {
  guia$: Observable<any>
  responseOrder$: Observable<any>
  contextResponse: any = {}
  detailsGuide: any = {}
  headerLeft = ''
  params: any = []
  anexos: any = []
  historico: any = []
  historicoBene: any = []
  historicoPagination: any = []
  totalItensHistoricoNumber = 0
  itensPegos = 9
  arrayAlertDetails: any = []
  habilitarBtn = false
  listItensRegulados: any = []
  loadingReq = false
  errorReq = ''
  arraySolicitaionsNoIA: any = []
  arraySolicitaionsIA: any = []

  totalSolicitations = 0


  searchArrayHistory: any = []
  consultStringSeatch = ''

  constructor(
    private nextGuide: Store<GuiaService | any>,
    private guideService: GuiaService,
    private router: Router
  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
    this.responseOrder$ = this.nextGuide.select('arrayResponse')
  }

  ngOnInit(): void {
    this.guia$.subscribe(res => {
      if(res.id > 0) {
        this.detailsGuide = res
      } else {
        this.detailsGuide = {}
      }
    })

    //verifica se tem alguma guia na store, caso contrario pega no localstorage
    if(this.detailsGuide) {
      const auxNextGuide: any = localStorage.getItem('@OCT:NEXT_GUIDE');
      const formatNextGuide = JSON.parse(auxNextGuide)
      this.detailsGuide = formatNextGuide
    }

    this.headerLeft = `${this.detailsGuide?.codigo_no_cliente} • ${this.detailsGuide?.cliente.razao_social}`

    this.detailsGuide.parametros_ordenados.map((parametro: any) => {
      if(parametro.rotulo.indexOf('Anexo') !== -1) {
        this.anexos = [
          ...this.anexos,
          { ...parametro }
        ]
      } else if(parametro.rotulo.indexOf('Observações') !== -1){
        this.historico = [
          ...this.historico,
          { ...parametro }
        ]
      } else {
        this.params = [
          ...this.params,
          { ...parametro }
        ]
      }
    })

    // solicitacoes p/ responder
    this.detailsGuide.solicitacoes.map(((solicitation: any) => {
      // card regulados pela IA, caso contrario solicitacao para regular
      if(solicitation.tipo_resposta !== undefined && solicitation.tipo_resposta === 'automatica'){
        this.arraySolicitaionsIA = [
          ...this.arraySolicitaionsIA,
          solicitation
        ]
      } else {
        this.arraySolicitaionsNoIA = [
          ...this.arraySolicitaionsNoIA,
          solicitation
        ]
      }
    }))


    //populando var de pendencias
    this.arrayAlertDetails = Object.values(this.detailsGuide.pendencia_alerts)

    // logica para paginacao = primeiros 10 intes
    this.historicoBene = this.detailsGuide.historico
    this.totalItensHistoricoNumber = this.detailsGuide.historico.length

    const initialPag = this.historicoBene.slice(0, this.itensPegos)
    this.historicoPagination = initialPag

    //logica para habilitar btn
    setTimeout(() => {
      this.responseOrder$.subscribe(res => {
        this.listItensRegulados = []

        res.pedido?.solicitacoes_attributes.map((item: any) => {
          if(!!item.regulacoes_attributes[0].motivo) {
            this.listItensRegulados = [
              ...this.listItensRegulados,
              item.regulacoes_attributes[0].motivo
            ]
          }
        })

        const auxBody = {
          ...res.parametrosAux,
          proximo_pedido: res.proximo_pedido,
          pedido: res.pedido
        }

        this.contextResponse = auxBody
      })

    }, 1000)

    this.nextGuide.dispatch(REMOVE_RESPONSE_SOLICITATION())
  }

  nextItensHistory(): void {
    if(this.historicoPagination.length <= this.totalItensHistoricoNumber) {
      this.itensPegos += 10
      this.historicoPagination = this.historicoBene.slice(0, this.itensPegos)
    }
  }

  onRegularOrder(): void {
    this.loadingReq = true
    this.errorReq = ''

    // habilitar btn
    this.responseOrder$.subscribe(res => {

      const auxBody = {
        ...res.parametrosAux,
        proximo_pedido: res.proximo_pedido,
        pedido: res.pedido
      }
      this.contextResponse = auxBody
    })



    this.guideService
      .regularOrder(this.detailsGuide.id, this.contextResponse)
      .subscribe(
        res => {
          this.loadingReq = false
          if(this.detailsGuide.status === 'aguardando_parecer') {
            this.router.navigate(['homepage'])
          } else {
            this.router.navigate(['feedback-envio'])
          }

          this.nextGuide.dispatch(REMOVE_RESPONSE_SOLICITATION())
        },
        err => {
          this.loadingReq = false
          this.errorReq = 'Aconteceu um erro nos nossos servidores. Tente novamente em instante.'
        }
      )

  }

  handleSearchHistoric(e: any): void {
    this.searchArrayHistory = []
    // todas guias
    this.historicoPagination.map((guiaRegulation: any) => {
      // todas as solicitacoes de uma guia
      guiaRegulation.solicitacoes.map((solicitaion: any) => {
        // todos parametros de uma solicitacao
        solicitaion.parametros_ordenados.map((param: any) => {
          if(param.nome === 'nome_procedimento') {
            if (param.valor.toLowerCase().indexOf(String(e.target.value).toLowerCase()) > -1) {
              let guiaAux = {
                ...guiaRegulation,
                [guiaRegulation.solicitacoes]: [
                  solicitaion
                ]
              }
              this.searchArrayHistory = [
                ...this.searchArrayHistory,
                guiaAux
              ]
            }
          }
        })
      })
    })
    this.consultStringSeatch = e.target.value
  }
}
