import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GuiaService } from 'src/app/shared/utils/services/guia.service';
import { ADD_GUILDE } from 'src/app/shared/context/guide/guide.actions';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  guia$: Observable<any>
  guideID = -1
  errorReq = ''

  options: AnimationOptions = {
    path: '../../../../assets/lottie/oct-success.json',
  };

  constructor(
    private router: Router,
    private nextGuide: Store<GuiaService | any>,
    private guiaService: GuiaService

  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
   }

  ngOnInit(): void {
    const guideAux: any = localStorage.getItem('@OCT:NEXT_GUIDE')
    const guideFormat = JSON.parse(guideAux)

    this.errorReq = ''
    this.guiaService
    .handleGetNextGuide()
    .subscribe(
      (res: any) => {
        if(res.body.id !== undefined) {
          this.nextGuide.dispatch(ADD_GUILDE(res.body))

          localStorage.setItem('@OCT:NEXT_GUIDE', JSON.stringify(res.body))

          this.guideID = res.body.id

          setTimeout(() => {
            this.router.navigate(['regulacao/detalhes-solicitacao/', this.guideID])
          }, 3000)

        } else {
          this.guideID = 0

          setTimeout(() => {
            this.router.navigate(['homepage'])
          }, 3000)
        }
      },
        (error) => {
          this.errorReq = 'Aconteceu um erro nos nossos servidores. Estamos redirecionando você para Homepage'

          setTimeout(() => {
            this.router.navigate(['homepage'])
          }, 3000)
        }
    )

  }

}
