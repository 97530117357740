import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'oct-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() data: any = []
  auxData: any = []

  constructor() { }

  ngOnInit(): void {
    this.data.map((guilde: any) => {
      this.auxData = [
        ...this.auxData,
        { label: guilde.titulo }
      ]
    })
  }

}
