import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RESPONSE_SOLICITATION } from '../../context/guide/guide.actions';
import { GuiaService } from '../../utils/services/guia.service';
import { GuidelineService } from '../../utils/services/guideline.service';

@Component({
  selector: 'app-card-ia',
  templateUrl: './card-ia.component.html',
  styleUrls: ['./card-ia.component.scss']
})
export class CardIaComponent implements OnInit {

  arrayRes$: Observable<any>
  contextResponse: any

  @Input() solicitation: any = []

  alert = false;
  titleSolicitation = 'Procedimento não encontrado'
  restParams: any = []
  ocorrencias: any = []
  formRequestSelected = ''
  formRequestSelectedAux = ''
  paramsEditRegulacao: any = {}
  paramsEditRegulacaoDecimal: any = {}
  showDrawer = false
  arrayGroupMotivos: any = []
  dataSelect: any  = []
  quantidadeDecimal = false
  quantAutorizadaError = ''
  auxArrayMo: any = []
  lastMotivo = ''

  parametroEditavelRecusadoPendente: any

  motivosBusca: any = []
  consultStringSearch = ''
  labelIAReq = ''


  // var da respota (inputs e selects)
  quat_dos_den_autorizada = 0
  quantAutoString: any = {}
  respostaMotivo = 'Autorizado automaticamente'
  quantAutorizada: any
  selectedMotivo: any = {
    motivo: ''
  }

  // "debounce"
  time: any = null

  constructor(
    private responseSolicitation: Store<GuiaService | any>
  ) {
    this.arrayRes$ = this.responseSolicitation.select('arrayResponse')
  }

  ngOnInit(): void {
    // label para tag
    const data = new Date(this.solicitation.respondido_at)
    const dia = Number(data.getDate()) < 10 ? `0${data.getDate()}` : data.getDate()
    const mes = Number(data.getMonth() + 1) < 10 ? `0${data.getMonth() + 1}` : data.getMonth() + 1
    const ano = data.getFullYear()
    const dataFormat = `${dia}/${mes}/${ano}`

    this.labelIAReq = `Procedimento autorizado em ${dataFormat}`

    // mostrando informacoes para usuario
    this.solicitation.parametros_ordenados.map((params: any) => {
      if(params.nome.indexOf('nome_procedimento') !== -1) {
        this.titleSolicitation = params.valor
      } else  if(params.nome.indexOf('ocorrencias') !== -1) {
        let auxOcorr = JSON.parse(params.valor)
        this.ocorrencias = [
          ...this.ocorrencias,
          ...auxOcorr
        ]
      } else {
        this.restParams = [
          ...this.restParams,
          { ...params }
        ]
      }
    })


    // buscar um parametro que é editavel && do tipo integer (quantidade)
    this.solicitation.parametros_ordenados.map((params: any) => {
      if(params.tipo === 'integer' && params.nome === 'quantidade') {
        this.paramsEditRegulacao = {
          id: params.id,
          nome: params.nome,
          valor: params.valor,
          rotulo: params.rotulo,
        }

          for(let i = 1; i<= parseInt(params.valor); i++) {
            this.dataSelect = [
              ...this.dataSelect,
              { label: String(i), value: String(i) }
            ]
          }

        this.quantAutoString = this.dataSelect[0]
        this.quat_dos_den_autorizada = this.quantAutoString.label

      } else if (params.editavel && params.tipo === 'decimal') { // se nao editavel && decimal

        this.paramsEditRegulacaoDecimal = {
          id: params.id,
          nome: params.nome,
          valor: params.valor,
          rotulo: params.rotulo,
        }
      }
    })

    // mando para CONTEXT a resposta da IA
    this.onSavedResponseGuide()
  }

  // logica para request
  onSavedResponseGuide(): void {
    // pegando a resposta do context
    this.arrayRes$.subscribe(res => {
      this.contextResponse = res
    })

    // motando a resposta
    let responseSolicitation: any = {}
    // troquei de regulacao_id para id
    this.parametroEditavelRecusadoPendente =  {  [`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacao.nome}`]: Number(this.paramsEditRegulacao.valor) }

    if(this.solicitation.resposta === 'aprovada') {
      if(this.paramsEditRegulacao.valor > 0) {

          // caso exista parametros editavel para regular
          if(this.paramsEditRegulacaoDecimal.rotulo !== undefined) {
            let auxQuantAutorizada = ''

            if(this.quantAutorizada !== undefined) {
              auxQuantAutorizada = this.quantAutorizada.replace(',0', '')
            }

            this.parametroEditavelRecusadoPendente = {
              [`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacao.nome}`]: Number(this.quat_dos_den_autorizada),
              [`parametros_editados_regulacao_${this.solicitation.regulacao_id}_${this.paramsEditRegulacaoDecimal.nome}`]: auxQuantAutorizada,
            }
          }

          if(this.contextResponse.pedido?.solicitacoes_attributes === undefined) {
            responseSolicitation = {
              parametrosAux: {
                ...this.parametroEditavelRecusadoPendente
              },
              proximo_pedido: false,
              pedido: {
                solicitacoes_attributes: [
                  {
                    id: this.solicitation.id,
                    regulacoes_attributes: [
                        {
                          tipo_resposta: 'automatica',
                          resposta: this.solicitation.resposta,
                          motivo: this.respostaMotivo,
                          laudo_id: this.selectedMotivo.laudo_id,
                          id: this.solicitation.regulacao_id,
                        }
                    ]
                  }
                ]
              }
            }
          } else {
            const filter = this.contextResponse.pedido?.solicitacoes_attributes
                .filter((auxContextRes: any) => this.solicitation.id !== auxContextRes.id)

            responseSolicitation = {
              parametrosAux: {
                ...this.contextResponse.parametrosAux,
                ...this.parametroEditavelRecusadoPendente
              },
              proximo_pedido: false,
              pedido: {
                solicitacoes_attributes: [
                  ...filter,
                  {
                    id: this.solicitation.id,
                    regulacoes_attributes: [
                        {
                          tipo_resposta: 'automatica',
                          resposta: this.solicitation.resposta,
                          motivo: this.respostaMotivo,
                          laudo_id: this.selectedMotivo.laudo_id,
                          id: this.solicitation.regulacao_id,
                        }
                    ]
                  }
                ]
              }
            }
          }

      // reposta para contexto
       this.responseSolicitation.dispatch(RESPONSE_SOLICITATION(responseSolicitation))

      }
    }

  }
}
