import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { BASE_URL } from "../constants/api";


@Injectable({ providedIn: 'root' })
export class GuiaService {
  page = 1

  constructor(
    private http: HttpClient,
  ) {}

  handleGetHistory(): Observable<any> {
    return this.http
      .get<HttpResponse<Object>>(`${BASE_URL}/pedidos`, { observe: "response" })
      .pipe(tap( res => {
        this.page += 1
      }))
  }

  handleGetReqWaitingOption() {
    return this.http
    .get<HttpResponse<Object>>(`${BASE_URL}/pedidos/aguardando_parecer`, { observe: "response" })
  }

  handleGetNextPageRequests() {
    return this.http
    .get<any>(`${BASE_URL}/pedidos?page=${this.page}`, { observe: "response" })
    .pipe(tap( res => {
      this.page += 1
    }))
  }

  handleSearchGuiaResquest(query: any) {
    return this.http
      .get<any>(`${BASE_URL}/pedidos/search/${query}'`, { observe: 'response' })
  }

  handelSearchGuiaQueue(query: any) {
    return this.http
      .get<any>(`${BASE_URL}/pedidos/${query}/search`, { observe: 'response' })
  }

  handleGetNextGuide() {
    return this.http
        .get(`${BASE_URL}/pedidos/proximo`, { observe: 'response' })
  }

  getOrder(order: number) {
    return this.http
      .get<any>(`${BASE_URL}/pedidos/${order}`, { observe: 'response' })
  }


  arquivarGuia(guideID: number) {
    return this.http
     .put<any>(`${BASE_URL}/pedidos/${guideID}/aguardar_parecer`, { observe: 'response' })
  }

  getMotivos(id: number, respons: string): Observable<any> {
    const body: any = {
      resposta: respons
    }

    return this.http
     .get<HttpResponse<Object>>(`${BASE_URL}/solicitacoes/${id}/laudos`, body)
  }

  regularOrder(id: number, body: any): Observable<any> {
    return this.http
    .put<HttpResponse<Object>>(`${BASE_URL}/pedidos/${id}/regular`, body, { observe: "response" })
  }
}
