<div class="container" *ngIf="detectedDevice === 'DesktopOrTablet'">
  <div class="container__logo">
    <img src="../../../../assets/logos/octopus-white-large.svg" alt="octopus"/>
  </div>

  <div class="container__form">
    <form [formGroup]="loginForm">
      <h1>Bem-vindo ao Octopus</h1>
      <p>Preencha os campos abaixo para acessar o sistema.</p>

      <mai-input
        placeholder="e-mail"
        iconLeft="mai-ic-email"
        [state]="isErrorReqEmail || !!errorEmail ? 'danger' : 'default'"
        [assistiveText]='errorEmail'
      ><input
        #emailInput
        type="email"
        (keyup)="email = emailInput.value"
        formControlName="email"
        (focus)="removeErrorEmail()"
      /></mai-input>

      <mai-input
        placeholder="senha"
        iconLeft="mai-ic-password"
        [state]="isErrorReqPassword ? 'danger' : 'default'"
      ><input
        type="password"
        #senhaInput
        (keyup)="senha = senhaInput.value"
        formControlName="password"
        (focus)="removeErrorPassword()"
      /></mai-input>

      <mai-btn
        id="btnSign"
        [label]="!loading ? 'entrar' : 'carregando...'"
        [disabled]="(!email || !senha) || loading"
        (clicked)="handleSignin()"
      ></mai-btn>

      <div class="forgotContainer">
        <mai-btn
          label="ESQUECI MINHA SENHA"
          type="text"
          (clicked)="handleRedirectForgot()"
        ></mai-btn>
      </div>



      <mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
    </form>

    <p>Octopus é um produto <a href="#">maida.health.</a></p>
  </div>
</div>


<div class="device" *ngIf="detectedDevice === 'Android' || detectedDevice === 'Ios'">
  <app-device-redirect [typeDevice]="detectedDevice"></app-device-redirect>
</div>
