import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GuidelineData } from "src/app/modules/Manager/guideline/guideline.model";
import { BASE_URL } from "../constants/api";


@Injectable({ providedIn: 'root' })
export class GuidelineService {
  page = 1

  constructor(
    private http: HttpClient,
  ) {}

  getGuidelines(): Observable<any> {
    return this.http
      .get<HttpResponse<Object>>(`${BASE_URL}/guidelines/`, { observe: "response" })
  }

  createGuideline(req: GuidelineData): Observable<any> {
    return this.http
    .post<HttpResponse<Object>>(`${BASE_URL}/guidelines/`, req, { observe: "response" })
  }

  removeGuideline(id: number): Observable<any> {
    return this.http
    .delete<HttpResponse<Object>>(`${BASE_URL}/guidelines/${id}`, { observe: "response" })
  }

  updateGuideline(id: number, body: GuidelineData): Observable<any> {
    return this.http
    .put<HttpResponse<Object>>(`${BASE_URL}/guidelines/${id}`, body, { observe: "response" })
  }

  getMotivos(id: number): Observable<any> {
    return this.http
    .get<HttpResponse<Object>>(`${BASE_URL}/solicitacoes/${id}/laudos`, { observe: "response" })
  }

}
