import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GuidelineService } from 'src/app/shared/utils/services/guideline.service';
import { GuidelineData } from './guideline.model';

@Component({
  selector: 'app-guideline',
  templateUrl: './guideline.component.html',
  styleUrls: ['./guideline.component.scss']
})
export class GuidelineComponent implements OnInit {
  show = false
  guidelines: GuidelineData[] = []
  labelAlertSuccess = ''

  // valores form
  nameProcedure: string | any = ''
  codeProcedure: string | any = ''
  description: string | any = ''

  errorReq = ''

  guidelineEdit: any = {}

  constructor(
    private guidelineService: GuidelineService
  ) { }

  ngOnInit(): void {
    this.guidelineService
      .getGuidelines()
      .subscribe(res => {
        this.guidelines = res.body.guidelines
      },
      (err) => {
        this.errorReq = 'Aconteceu um erro em nosso servidores! Tente novamente em instantes.'

        setTimeout(() => {
          this.errorReq = ''
        }, 2000)
      }
      )
  }


  handleDrawer() {
    if(this.show) {
      this.nameProcedure = ''
      this.codeProcedure = ''
      this.description = ''

      this.guidelineEdit = {}
    }

    this.show = !this.show;
  }



  // CREATE
  handleCreateOrEditGuideline(): void {
    const req = {
      procedure_name: this.nameProcedure.value,
      procedure_code: this.codeProcedure.value,
      description: this.description
    }

    if(this.guidelineEdit.id !== undefined) {
      this.guidelineService
      .updateGuideline(this.guidelineEdit.id, req)
      .subscribe(
        res => {
         const filter = this.guidelines.filter((guideline: any) => guideline.id !== this.guidelineEdit.id)

         this.guidelines = [
           ...filter,
           res.body.data
         ]
         this.labelAlertSuccess = 'Diretriz editada com sucesso'

         setTimeout(() => {
          this.show = !this.show
          this.labelAlertSuccess = ''

           // limpando campos
          this.nameProcedure = ''
          this.codeProcedure = ''
          this.description = ''

          this.guidelineEdit = {}
         }, 2000)
        },
        err => {
          this.errorReq = 'Aconteceu um erro em nosso servidores! Tente novamente em instantes.'

          setTimeout(() => {
            this.errorReq = ''
          }, 2000)
        }
      )
    } else {
      this.guidelineService
      .createGuideline(req)
      .subscribe(
        res => {
         this.guidelines = [
           ...this.guidelines,
           res.body.data
         ]

         // limpando campos
         this.nameProcedure = ''
         this.codeProcedure = ''
         this.description = ''

         this.labelAlertSuccess = 'Diretriz criada com sucesso'

         setTimeout(() => {
          this.show = !this.show
          this.labelAlertSuccess = ''
         }, 2000)
        },
        err => {
          this.errorReq = 'Aconteceu um erro em nosso servidores! Tente novamente em instantes.'

          setTimeout(() => {
            this.errorReq = ''
          }, 2000)
        }
      )
    }
  }

  // controladores select e textarea
  handleNameProcedure(e: any): void {
    this.nameProcedure = { label: e.label, value: e.label}
  }

  handleCodeProcedure(e: any): void {
    this.codeProcedure = { label: e.label, value: e.label}
  }

  handleDescriptionValue(e: any): void {
    this.description = e.target.value
  }

  handleOverflowSelected(e: any, guideline: any): void {
    if(e.label === 'Remover') {
      this.guidelineService
        .removeGuideline(guideline.id)
        .subscribe(
          res => {
            const filter = this.guidelines.filter((guid: any) => guid.id !== guideline.id)
            this.guidelines = filter
          },
          err => {
            this.errorReq = 'Aconteceu um erro em nosso servidores! Tente novamente em instantes.'

            setTimeout(() => {
              this.errorReq = ''
            }, 2000)
          }
        )
    } else {
      this.nameProcedure = { label: guideline.procedure_name, value: guideline.procedure_name}

      this.codeProcedure =   { label:guideline.procedure_code, value:guideline.procedure_code }
      this.description = guideline.description

      this.guidelineEdit = guideline

      this.show = !this.show
    }
  }
}
