<oct-guideline>
  <header>
    <mai-btn-cta
      label="CADASTRAR NOVA DIRETRIZ"
      (clicked)="handleDrawer()"
    ></mai-btn-cta>
    <div class="container_search">
      <mai-searchbar
        [btnRight]='{label:"mai-ic-filter", alt:"teste"}' placeholder='Digite a sua busca aqui'
      ><input type="text"
      /></mai-searchbar>
    </div>
  </header>

  <div class="container_guidelines">
    <div class="container_guidelines--guideline" *ngFor="let guideline of guidelines">
      <div class="container_card_manager">
        <div class="content_left">
          <img src="../../../../../assets/icons/mai-ic-attach.svg" alt=""/>
          <h3>{{guideline.procedure_name}}</h3>
        </div>

        <div class="content_right">
          <mai-overflow
            [data]="[{ label: 'Editar', icon: 'mai-ic-edit' }, { label: 'Remover', icon: 'mai-ic-trash' }]"
            (clicked)="handleOverflowSelected($event, guideline)"
          ></mai-overflow>
        </div>
      </div>
    </div>
  </div>


  <!-- INICIO DRAWER -->
  <mai-drawer
     (clickWrapper)='handleDrawer()'
     *ngIf='show'
  >
    <div class="contentDrawer">
      <div class="contentDrawer__header" >
        <header>
          <button type="button" (click)="handleDrawer()">
            <mai-svg-icon  icon="mai-ic-close" size='2'></mai-svg-icon>
          </button>

          <h2>Cadastrar nova diretriz</h2>
        </header>

        <form (submit)="handleCreateOrEditGuideline()">
          <div class="content-form">
            <div class="container-input">
              <label>Nome do Procedimento</label>
              <mai-select
                [haveSearch]='true'
                [data]="[{ label: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento', value: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento'}, { label: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento', value: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento'} ]"
                placeholder='Insira aqui o nome'
                (clicked)="handleNameProcedure($event)"
                [selected]="nameProcedure"
              >
              </mai-select>
            </div>

            <div class="container-input">
              <label>Código do Procedimento</label>
              <mai-select
                [haveSearch]='true'
                [data]="[{ label: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento', value: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento'}, { label: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento', value: 'Radioterapia Conformada Tridimensional (RCT-3D) = por tratamento'}]"
                placeholder='Insira aqui o código'
                (clicked)="handleCodeProcedure($event)"
                [selected]="codeProcedure"
              >
              </mai-select>
            </div>

            <div class="container-input">
              <label>Descrição do documento</label>
              <mai-input
                [isDefault]="false"
                placeholder="Insira aqui uma breve descrição..."
              >
                <textarea
                  rows='10'
                  [value]="description"
                  (input)="handleDescriptionValue($event)"
                ></textarea>
              </mai-input>
            </div>
          </div>
          <div class="conteiner-button">

            <div class="buttonContainerNoSubmit">
              <button class="noSubmit" type="button" (click)="handleDrawer()">
                Cancelar
              </button>
            </div>

            <mai-btn
              label="SALVAR"
              [disabled]="(!nameProcedure || !codeProcedure || !description)"
            ></mai-btn>

          </div>
        </form>
      </div>
    </div>
  </mai-drawer>

</oct-guideline>


<!-- //ALERT -->
<mai-alert type='success' *ngIf='!!labelAlertSuccess' [label]='labelAlertSuccess'></mai-alert>
<mai-alert type='danger' *ngIf='!!errorReq' [label]='errorReq'></mai-alert>
