import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { GestorGuard } from './core/auth/gestor.guard';
import { PrivateGuard } from './core/auth/private.guard';
import { GuidelineComponent } from './modules/Manager/guideline/guideline.component';
import { DetailsRequestComponent } from './modules/RegulatorDoctor/details-request/details-request.component';
import { FeedbackNoSendComponent } from './modules/RegulatorDoctor/feedback-no-send/feedback.component';
import { FeedbackComponent } from './modules/RegulatorDoctor/feedback/feedback.component';
import { ForgotPasswordComponent } from './modules/RegulatorDoctor/forgot-password/forgot-password.component';
import { HomepageComponent } from './modules/RegulatorDoctor/homepage/homepage.component';

import { SigninComponent } from './modules/RegulatorDoctor/signin/signin.component'

const routes: Routes = [
  { path: '', component: SigninComponent, canActivate: [AuthGuard] },
  { path: 'recuperar-senha', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'regulacao/detalhes-solicitacao/:id', component: DetailsRequestComponent, canActivate: [PrivateGuard] },
  {
    path: 'homepage',
    component: HomepageComponent,
    canActivate: [PrivateGuard]
  },
  { path: 'feedback-envio', component: FeedbackComponent, canActivate: [PrivateGuard] },
  { path: 'feedback', component: FeedbackNoSendComponent, canActivate: [PrivateGuard] },
  //gestor
  { path: 'gestor', component: GuidelineComponent, canActivate: [GestorGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
