import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { Observable } from 'rxjs';
import { GuiaService } from 'src/app/shared/utils/services/guia.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() request: any = []
  @Input() idGuide = 0
  guia$: Observable<any>
  status = ''
  loading = false

  guiaId = 1
  show = false
  detailsGuide: any = {}
  headerLeft = ''
  params: any = []
  anexos: any = []
  historico: any = []
  historicoBene: any = []
  historicoPagination: any = []
  totalItensHistoricoNumber = 0
  itensPegos = 9
  arrayAlertDetails: any = []
  nomeProcedimento = ''


  options: AnimationOptions = {
    path: '../../../../assets/lottie/octopus-search.json',
  };

  constructor(
    private router: Router,
    private guiaService: GuiaService,
    private nextGuide: Store<GuiaService | any>
  ) {
    this.guia$ = this.nextGuide.select('nextGuilde')
  }

  ngOnInit(): void {
    this.request.parametros_ordenados.map((params: any) => {
      if(params.nome.indexOf("nome_procedimento") !== -1) {
        this.nomeProcedimento = params.valor
      }
    })
  }

  handlePush(): void {
    this.show = !this.show
    this.loading = true

    if(this.show) {
      this.guiaService
      .getOrder(this.idGuide)
      .subscribe(
        (res: any) => {
          this.guiaId = res.body.id

          this.detailsGuide = res.body

          // this.headerLeft = `${res.body.codigo_no_cliente} • ${res.body.cliente.razao_social}`

          res.body.parametros_ordenados.map((parametro: any) => {
            if(parametro.rotulo.indexOf('Anexo') !== -1) {
              this.anexos = [
                ...this.anexos,
                { ...parametro }
              ]
            } else if(parametro.rotulo.indexOf('Observações') !== -1){
              this.historico = [
                ...this.historico,
                { ...parametro }
              ]
            } else {
              this.params = [
                ...this.params,
                { ...parametro }
              ]
            }
          })

          //populando var de pendencias
          this.arrayAlertDetails = Object.values(res.body.pendencia_alerts)

          // logica para paginacao = primeiros 10 intes
          this.historicoBene = res.body.historico
          this.totalItensHistoricoNumber = res.body.historico.length

          const initialPag = this.historicoBene.slice(0, this.itensPegos)
          this.historicoPagination = initialPag

          // loading
          this.loading = false
        },
        (error) => {
          this.guiaId = 0
          this.loading = false
        }
      )
    }
  }

  handleCancelReq() {
    this.show = !this.show
  }

  nextItensHistory(): void {
    if(this.historicoPagination.length <= this.totalItensHistoricoNumber) {
      this.itensPegos += 10
      this.historicoPagination = this.historicoBene.slice(0, this.itensPegos)
    }
  }

  animationCreated(animationItem: AnimationItem): void {
  }

}
