import { Component, Input, OnInit } from '@angular/core';
import { GuiaService } from '../../utils/services/guia.service';

@Component({
  selector: 'oct-card-historic',
  templateUrl: './card-historic.component.html',
  styleUrls: ['./card-historic.component.scss']
})
export class CardHistoricComponent implements OnInit {
  @Input() request: any = []
  @Input() idGuide: any

  nameProcedimento = ''
  ocorrencias: any = []
  restParams: any = []

  showAccordion = false
  loading = false
  guiaId = 1
  show = false
  detailsGuide: any = {}
  headerLeft = ''
  params: any = []
  anexos: any = []
  historico: any = []
  historicoBene: any = []
  historicoPagination: any = []
  totalItensHistoricoNumber = 0
  itensPegos = 9
  arrayAlertDetails: any = []
  nomeProcedimento = ''
  searchArrayHistory: any = []
  consultStringSeatch = ''

  constructor(
    private guiaService: GuiaService
  ) { }

  ngOnInit(): void {
    this.request.parametros_ordenados.map((param: any) => {


      if(param.nome === 'nome_procedimento' || param.nome === "procedimento") {
        this.nameProcedimento = param.valor
      } else if(param.nome === 'ocorrencias') {

      } else {
        const auxNomeReplace = param.nome.replaceAll('_', ' ');


        this.restParams = [
          ...this.restParams,
          { nome:auxNomeReplace, valor: param.valor }
        ]
      }
    })
  }


  onActivedAccordion() {
   this.showAccordion = !this.showAccordion
  }

  handlePush(): void {
    this.show = !this.show
    this.loading = true

    if(this.show) {
      this.guiaService
      .getOrder(this.idGuide)
      .subscribe(
        (res: any) => {
          this.guiaId = res.body.id

          this.detailsGuide = res.body

          // this.headerLeft = `${res.body.codigo_no_cliente} • ${res.body.cliente.razao_social}`

          res.body.parametros_ordenados.map((parametro: any) => {
            if(parametro.rotulo.indexOf('Anexo') !== -1) {
              this.anexos = [
                ...this.anexos,
                { ...parametro }
              ]
            } else if(parametro.rotulo.indexOf('Observações') !== -1){
              this.historico = [
                ...this.historico,
                { ...parametro }
              ]
            } else {
              this.params = [
                ...this.params,
                { ...parametro }
              ]
            }
          })

          //populando var de pendencias
          this.arrayAlertDetails = Object.values(res.body.pendencia_alerts)

          // logica para paginacao = primeiros 10 intes
          this.historicoBene = res.body.historico
          this.totalItensHistoricoNumber = res.body.historico.length

          const initialPag = this.historicoBene.slice(0, this.itensPegos)
          this.historicoPagination = initialPag

          // loading
          this.loading = false
        },
        (error) => {
          this.guiaId = 0
          this.loading = false
        }
      )
    }
  }

  handleCancelReq() {
    this.show = !this.show
  }

  nextItensHistory(): void {
    if(this.historicoPagination.length <= this.totalItensHistoricoNumber) {
      this.itensPegos += 10
      this.historicoPagination = this.historicoBene.slice(0, this.itensPegos)
    }
  }

  handleSearchHistoric(e: any): void {
    this.searchArrayHistory = []
    // todas guias
    this.historicoPagination.map((guiaRegulation: any) => {
      // todas as solicitacoes de uma guia
      guiaRegulation.solicitacoes.map((solicitaion: any) => {
        // todos parametros de uma solicitacao
        solicitaion.parametros_ordenados.map((param: any) => {
          if(param.nome === 'nome_procedimento') {
            if (param.valor.toLowerCase().indexOf(String(e.target.value).toLowerCase()) > -1) {
              let guiaAux = {
                ...guiaRegulation,
                [guiaRegulation.solicitacoes]: [
                  solicitaion
                ]
              }
              this.searchArrayHistory = [
                ...this.searchArrayHistory,
                guiaAux
              ]
            }
          }
        })
      })
    })
    this.consultStringSeatch = e.target.value
  }

}
