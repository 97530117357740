<div class="container_card" (click)="handlePush()">
  <div class="container_card_left">
   <div class="title_wrap">
    <h3>{{!!nomeProcedimento  ? nomeProcedimento : 'Procedimento não informado'}}</h3>
   </div>
    <div class="container_sub_label">
      <div class="sub_label">
        <strong>Código: </strong>
        <p>{{request?.id}}</p>
      </div>
      <div class="sub_label">
        <strong>Quantidade autorizada: </strong>
        <p>{{request?.quantidade}}</p>
      </div>
      <div class="sub_label">
        <strong>Data da regulação: </strong>
        <p>{{request?.data_da_regulacao | date: 'dd/MM/yyyy'}}</p>
      </div>
    </div>
  </div>
  <div class="container_card_right" *ngIf="request?.resposta === 'aprovada'">
    <app-tag-card
      type="success"
      label="Procedimento autorizado"
    ></app-tag-card>
  </div>
  <div class="container_card_right" *ngIf="request?.resposta === 'pendente'">
    <app-tag-card
      type="pending"
      label="Procedimento pendente"
    ></app-tag-card>
  </div>
  <div class="container_card_right" *ngIf="request?.resposta === 'recusada'">
    <app-tag-card
      type="error"
      label="Procedimento negado"
    ></app-tag-card>
  </div>
</div>



